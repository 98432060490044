<template>
  <div v-if="isOpen" class="modal fade show d-block z-3" tabindex="-1">
    <div @click="closeModal()" class="h-100 w-100 position-fixed z-1"></div>
    <div class="modal-dialog d-flex align-items-center justify-content-center z-2">
      <div class="modal-content p-3">
        <div class="d-flex justify-content-between align-items-center">
          <h2 class="modal-title flex-grow-1 text-center">Nuevo Modo</h2>
          <button type="button" class="btn-close" @click="closeModal()">&times;</button>
        </div>
        <form class="mt-4 p-4">
          <div class="form-group">
            <label for="name" class="mb-2">Nombre: <span class="text-danger">*</span></label>
            <input type="text" class="form-control w-50 shadow-sm rounded-3 text-start" id="name" v-model="name">
          </div>
          <div class="form-group mt-5">
            <div class="d-flex align-items-center">
              <label class="mr-5">Descuento aplicado a toda la carta (%):</label>
              <div class="input-group custom-input-group shadow-sm">
                <input type="number" class="form-control custom-input" v-model="discount" min="0" max="100">
                <button class="btn custom-btn" type="button" @click="decreaseDiscount">-</button>
                <button class="btn custom-btn" type="button" @click="increaseDiscount">+</button>
              </div>
            </div>
          </div>
          <div class="form-group mt-5">
            <label>Categorías a excluir:</label>
            <div class="my-3">
              <span class="badge me-2" style="background-color: #fea073" v-for="(category, index) in excludedCategories" :key="index">
                {{ category }}
                <button type="button" @click="removeCategory(category)" class="ms-2 btn btn-sm">&times;</button>
              </span>
            </div>
            <select class="form-control w-50 shadow-sm" v-model="selectedCategory" @change="addExcludedCategory">
              <option disabled value="">Selecciona una categoría</option>
              <option v-for="category in allCategories" :key="category">{{ category }}</option>
            </select>
          </div>
          <div class="form-group mt-5">
            <label>Nº max platos p.p./pedido:</label>
            <div class="input-group custom-input-group shadow-sm mt-3">
              <input type="number" class="form-control custom-input" v-model="platesMax" min="0" max="100">
              <button class="btn custom-btn" type="button" @click="decreasePlates">-</button>
              <button class="btn custom-btn" type="button" @click="increasePlates">+</button>
            </div>
          </div>
          <label class="fw-bold mt-5">Precio inicial por comensal</label>
          <button type="button" class="btn mb-2" @click="addPriceBlock"><img src="../assets/icons8-más-50.png" class="w-50"/></button>
          <div v-for="(priceBlock, index) in priceBlocks" :key="index" class="border w-75 p-4 mb-3 position-relative">
            <button v-if="priceBlocks.length > 1" @click="removePriceBlock(index)" class="btn btn-danger position-absolute" style="top: 10px; right: 10px;">&times;</button>
            <div class="form-group mt-3">
              <label>Días de la semana: <span class="text-danger">*</span></label>
              <div class="d-flex justify-content-between">
                <label v-for="(day, dayIndex) in days" :key="dayIndex" class="d-inline-block">
                  <input type="checkbox" :value="day" v-model="priceBlock.selectedDays" :id="'day-' + dayIndex + '-' + index">
                  {{ day }}
                </label>
              </div>
            </div>
            <div class="form-group mt-3">
              <label>Horario de aplicación: <span class="text-danger">*</span></label>
              <div class="d-flex align-items-center mb-2 ml-2">
                <span>de</span>
                <input type="time" class="form-control mx-2 text-center" style="width: 110px;" v-model="priceBlock.horaInicio"/>
                <span>a</span>
                <input type="time" class="form-control mx-2 text-center" style="width: 110px;" v-model="priceBlock.horaFin"/>
              </div>
            </div>
            <div class="d-flex align-items-center mt-4 mb-2 ml-2 w-50">
              <div class="col-6 mr-3">
                <div class="form-group position-relative">
                  <small class="position-absolute top-0 start-0">{{ priceBlock.adultPrice ? priceBlock.adultPrice + '€' : '' }}</small>
                  <input type="text" class="form-control text-start border-none border-bottom" placeholder="€" aria-label="Adult price" 
                        @input="formatPrice(priceBlock, 'adultPrice', $event)">
                  <div class="form-text">€/Adulto <span class="text-danger">*</span></div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group position-relative">
                  <small class="position-absolute top-0 start-0">{{ priceBlock.childPrice ? priceBlock.childPrice + '€' : ''}}</small>
                  <input type="text" class="form-control text-start border-none border-bottom" placeholder="€" aria-label="Child price" 
                        @input="formatPrice(priceBlock, 'childPrice', $event)">
                  <div class="form-text">€/Niño <span class="text-danger">*</span></div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="errorMessage" class="alert alert-danger">
            {{ errorMessage }}
          </div>
          <div class="d-flex justify-content-center">
            <button type="button" class="btn mt-3 text-white fw-bold" style="background-color: #091E33;" @click="saveModo">Añadir</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      isOpen: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        nombre: null,
        discount: 50,
        excludedCategories: ['bebidas', 'sushi especial'],
        allCategories: ['bebidas', 'sushi especial', 'entrantes', 'postres', 'principales'],
        selectedCategory: '',
        platesMax: 5,
        days: ['L', 'M', 'X', 'J', 'V', 'S', 'D'],
        priceBlocks: [
          {
            selectedDays: [],
            horaInicio: '',
            horaFin: '',
            adultPrice: '',
            childPrice: ''
          }
        ],
        errorMessage: ''
      };
    },
    methods: {
      closeModal() {
        this.$emit('close');
      },
      decreaseDiscount() {
        if (this.discount > 0) {
          this.discount -= 1;
        }
      },
      increaseDiscount() {
        if (this.discount < 100) {
          this.discount += 1;
        }
      },
      decreasePlates() {
        if (this.platesMax > 1) {
          this.platesMax -= 1;
        }
      },
      increasePlates() {
        this.platesMax += 1;
      },
      addExcludedCategory() {
        if (this.selectedCategory && !this.excludedCategories.includes(this.selectedCategory)) {
          this.excludedCategories.push(this.selectedCategory);
          this.selectedCategory = '';
        }
      },
      removeCategory(categoria) {
        this.excludedCategories = this.excludedCategories.filter(cat => cat !== categoria);
      },
      addPriceBlock() {
        this.priceBlocks.push({
          selectedDays: [],
          horaInicio: '',
          horaFin: '',
          adultPrice: '',
          childPrice: ''
        });
      },
      removePriceBlock(index) {
        this.priceBlocks.splice(index, 1);
      },
      formatPrice(block, type, event) {
        let price = event.target.value.replace(',', '.'); 
        
        let formattedPrice = parseFloat(price);
        if (isNaN(formattedPrice) || formattedPrice <= 0) {
          formattedPrice = '0.00';
        } else {
          formattedPrice = formattedPrice.toFixed(2);
        }

        block[type] = formattedPrice + ' €';
      },

      saveModo() {
        if (!this.nombre) {
          this.errorMessage = 'El nombre del modo es obligatorio.';
          return;
        }

        const hasInvalidBlock = this.priceBlocks.some(block => {
          if (!block.horaInicio || !block.horaFin) {
            this.errorMessage = 'RANGO DE HORAS DE APLICACIÓN ERRÓNEO.';
            return true;
          }

          const adultPrice = parseFloat(block.adultPrice);
          const childPrice = parseFloat(block.childPrice);
          if (isNaN(adultPrice) || adultPrice <= 0 || isNaN(childPrice) || childPrice <= 0) {
            this.errorMessage = 'PRECIOS INCORRECTOS.';
            return true;
          }

          return false;
        });

        if (hasInvalidBlock) {
          return;
        }

        this.$emit('guardar', {
          name: this.name,
          discount: this.discount,
          excludedCategories: this.excludedCategories,
          platesMax: this.platesMax,
          priceBlocks: this.priceBlocks
        });

        this.closeModal();
        this.errorMessage = '';
      }
    }
  };
</script>

<style scoped>
  .modal {
    background-color: #f476005b;
  }
  
  .modal-dialog {
    min-height: 90vh;
    min-width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal-content {
    margin: auto;
  }

  .btn-close {
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
  }

  .custom-input-group {
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 50px;
    overflow: hidden;
    width: fit-content;
  }

  .custom-input {
    text-align: center;
    border: none;
    width: 60px;
    padding: 5px;
    font-size: 16px;
    outline: none;
  }

  .custom-btn {
    background-color: white;
    border: none;
    padding: 5px 10px;
    font-size: 18px;
    cursor: pointer;
  }

  .custom-btn:focus,
  .custom-input:focus {
    outline: none;
  }

  .custom-btn:hover {
    background-color: #f0f0f0;
  }

  input[type="number"]::-webkit-inner-spin-button, 
  input[type="number"]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  .form-group label {
    font-weight: bold;
  }

  .form-control {
    text-align: center;
  }

  .d-flex.justify-content-between input[type="radio"] {
    margin-right: 10px;
  }

  .d-flex.justify-content-center {
    justify-content: center;
  }
  
</style>
