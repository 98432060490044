<template>
  <div class="app">
    <h1 class="text-center mt-4 fw-normal mb-3">Carrito</h1>
    <div class="px-4" id="order-info">
      <span class="fw-semibold"> {{ totalPlates }} <span v-if="max_plates != null">/ {{ max_plates }}</span> platos</span>
      <span id="total-price">Total: {{ totalPrice.toFixed(2) }}€</span>
    </div>
    <div v-if="plates.length > 0">
      <div class="search-btn mx-4 mt-3">
        <i class="fa-solid fa-search "></i>
        <input autocomplete="off" class="search-input" placeholder="Buscar" v-model="search">
      </div>

      <div class="mx-2 m-0 px-3 mt-4 mb-5" id="div-platos"  v-if="this.FilterPlates().length > 0">
        <div class="plate shadow rounded-4 py-2 mb-3" v-for="(plate, index) in this.FilterPlates()" :key="index">
          <img :src="plate.photo ? plate.photo : noPhoto" class="card-img-top rounded-4" :id="`${plate.plate_id}_img`" alt="...">
          <p class="mt-2 text-center mb-1">{{ plate.name }}</p>
          <div class="row d-flex justify-content-between">
            <span class="ms-3 mt-1 w-auto price fw-semibold px-0" >{{ plate.price }}€</span>
            <div class="w-auto ps-0 me-2 mt-1">
              <i class="fa-regular fa-trash-can botonCantidad py-1 me-2 px-1" v-if="plate.amount == 1" @click="OpenDelete(plate.plate_id)"></i>
              <i class="fa-solid fa-minus botonCantidad py-1 me-2 px-1" @click="ChangeAmount(plate.plate_id, -1)" v-else></i>
              <span style="color: #19344F" class="fw-bold">{{plate.amount}}</span>
              <i class="fa-solid fa-plus botonCantidad py-1 ms-2 px-1" @click="ChangeAmount(plate.plate_id, 1)"></i>
            </div>
          </div>
        </div>
        <div class="w-100 d-flex justify-content-center mb-5 ">
          <p @click="FinalizarPedido()" id="finalize-order-button" class="px-5 shadow">Finalizar Pedido</p>
        </div>
      </div>
      <div v-else class="col-9 mx-auto row m-0 mt-5" style="height: 100%;">
        <img src="./../assets/not_plates_founds.png" class="col-xl-4 col-lg-5 col-md-7 col-sm-9 mx-auto">
        <p class="text-center fs-4 fw-semibold mt-4">No hemos encontrado platos con esta búsqueda!</p>
      </div>
    </div>
    <div v-else class="h-75 d-flex align-items-center justify-content-center">
      <div class="row">
        <img class="mx-auto col-xl-4 col-lg-5 col-md-7 col-sm-9" src="../../src/assets/empty-cart.png"/>
        <h2 class="text-center">Tu carrito está vacio</h2>
      </div>
    </div>
    
    <DeleteProductConfirm
      :visible="isDeleteModalVisible"
      :plate="plateToDelete"
      @confirm="confirmDelete"
      @cancel="cancelDelete"
    ></DeleteProductConfirm>
    <SpinnerComponent id="spinner"></SpinnerComponent>
  </div>
</template>

<script>
import DeleteProductConfirm from '@/components/DeleteProductConfirm.vue'
import axios from 'axios';
import { mapState } from 'vuex';
import router from '@/router';
import SpinnerComponent from '../components/SpinnerComponent.vue'
import noPhoto from './../assets/no_plate.png';

export default {
  name: 'AppCartComponent',
  data() {
    return {
      plates: [],
      isDeleteModalVisible: false,
      plateToDelete: null,
      pollingInterval: null,
      search: "",
      first_charge:true,
      noPhoto
    };
  },
  props: {
    // Declaramos la prop 'message' que recibimos del padre
    max_plates: {
      type: Number,
    }
  },
  computed: {
    totalPrice() {
      return this.plates.reduce((total, plate) => total + plate.price * plate.amount, 0);
    },
    totalPlates() {
      return this.plates.reduce((total, plate) => total + plate.amount, 0);
    },
    ...mapState(['token']),
  },
  components: {
    DeleteProductConfirm,
    SpinnerComponent
  },
  methods: {
    async FinalizarPedido(){
      console.log(this.token)
      const url = 'http://127.0.0.1:8000/v1/api/app/create_order';
      try {
        const response = await axios.post(url, {}, {
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        });
        console.log(response.data);
        this.$emit('ReviseSession');
        router.push({ name: "MisPedidos" });
      } catch (error) {
        console.error('Error fetching shopping cart:', error);
      }
    },
    FilterPlates(){
      let plates_filtered = this.plates
      if (this.search != ""){
        plates_filtered = plates_filtered.filter(plate => plate.name.toString().toLowerCase().includes(this.search.toString().toLowerCase()))
      }

      return plates_filtered
    },
    async fetchShoppingCart() {
      const token = this.token; // Suponiendo que tienes el token almacenado en Vuex
      const url = 'http://127.0.0.1:8000/v1/api/app/ShoppingCart';
      try {
        const response = await axios.get(url, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        // Maneja la respuesta
        console.log(response.data);
        this.plates = response.data
        if(this.first_charge){
          document.getElementById("spinner").classList.add("d-none")
          this.first_charge=false
        }
      } catch (error) {
        // Maneja el error
        this.plates = []
        console.error('Error fetching shopping cart:', error);
        document.getElementById("spinner").classList.add("d-none")
      }
    },
    startPolling() {
      this.fetchShoppingCart();
      this.pollingInterval = setInterval(this.fetchShoppingCart, 5000); // Cada 5 segundos
    },
    stopPolling() {
      clearInterval(this.pollingInterval);
    },
    
    ChangeAmount(plateId, change){
      console.log("hola")
      let plateChanged = this.plates.filter(plate => plate.plate_id == plateId)[0]
      console.log(plateChanged)
      plateChanged.amount += change
      console.log(plateChanged)
      this.changeAmountAPI(plateChanged.plate_id, plateChanged.amount)
    },
    OpenDelete(plateId){
      let plateChanged = this.plates.filter(plate => plate.plate_id == plateId)[0]
      this.plateToDelete = plateChanged;
      this.isDeleteModalVisible = true;
    },
    confirmDelete() {
      this.changeAmountAPI(this.plateToDelete.plate_id, 0)
      this.isDeleteModalVisible = false;
    },
    cancelDelete() {
      this.isDeleteModalVisible = false;
    },
    async changeAmountAPI(plate_id, new_amount) {
      console.log(this.token)

      // Comprobacion maximo de cantidad de platos
      if(this.max_plates != null){
        let plate = this.plates.filter(plate => plate.plate_id == plate_id)[0]
        plate.amount = new_amount
        if (this.totalPlates > this.max_plates){
          this.fetchShoppingCart()
          let image = document.getElementById(plate_id+"_img")
          image.classList.add('shake');
          setTimeout(() => {
            image.classList.remove('shake');
          }, 500);
          return
        }
      }
      
      const url = 'http://127.0.0.1:8000/v1/api/app/addShoppingCart';
      const params = {
        plate_id: plate_id,
        amount: new_amount
      };
      try {
        const response = await axios.post(url, params, {
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        });
        console.log(response.data);
        this.fetchShoppingCart()
        this.$emit('ReviseSession');
      } catch (error) {
        console.error('Error fetching shopping cart:', error);
      }
    }
  },
  created() {
    this.startPolling();
  },
  beforeUnmount() {
    this.stopPolling();
  }
};
</script>

<style>
#app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

  .shake {
    display: inline-block;
    animation: shake 0.5s ease-in-out;
    animation-iteration-count: infinite;
  }

  /* Definimos la animación para el gesto de negación */
  @keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-10px); }
    50% { transform: translateX(10px); }
    75% { transform: translateX(-10px); }
    100% { transform: translateX(0); }
  }

#header {
  padding: 20px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

#header h1 {
  margin: 0;
  font-size: 24px;
}

#order-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#order-info #total-price {
  font-size: 20px;
  font-weight: bold;
}

.search-input{
  box-shadow: 0px;
  width: 90%;
  border: 0px;
}

.search-input:focus{
  border: 0px !important;
  outline: none;
}
  
.search-btn{
  display: flex;
  height: 55px;
  background: #fff;
  padding: 20px;
  font-size: 18px;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  box-shadow: 0 0 5px rgba(0,0,0,0.1);
  border: 2px solid #dbdbdb;
  border-radius: 13px;
  color: #0A2533;
}

.price{
    color: #F47700;
  }

.botonCantidad{
    border: 2px solid #f47600a7;
    border-radius: 10px;
    color: #F47700;
  }

#finalize-order-button {
  background-color: #ff7300;
  color: white;
  border: none;
  padding: 10px;
  font-size: 15px;
  cursor: pointer;
  margin: auto;
  border-radius: 5rem;
  text-decoration: none;
}

.botonCantidad{
    border: 1px solid #f47600a7;
    border-radius: 10px;
    padding: 0px 7px 0px 7px;
    color: #F47700;
  }

  #div-platos{
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }

  #div-platos img{
    height: 100px;
    object-fit: cover;
  }

  #div-platos p{
    max-height: 59px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .plate{
    flex: 1 1 calc(50% - 15px);
    max-width: calc(50% - 15px);
  }

  @media (min-width: 768px) {
    #div-platos img{
      height: 150px;
    }
    .plate{
      flex: 1 1 calc(33% - 15px);
      max-width: calc(33% - 15px);
    }
  }

  @media (min-width: 992px) {
    #div-platos img{
      height: 280px;
    }
    .plate{
      flex: 1 1 calc(25% - 15px);
      max-width: calc(25% - 15px);
    }
  }

  @media (min-width: 1200px) {
    #div-platos img{
      height: 280px;
    }
    .plate{
      flex: 1 1 calc(20% - 15px);
      max-width: calc(20% - 15px);
    }
  }
</style>