import { createStore } from 'vuex';
import axios from 'axios';

async function ComprobarToken(commit) {
  const tokenString = localStorage.getItem('token');
  let token;

  if (tokenString && tokenString !== "null") {
    token = JSON.parse(tokenString);

    try {
      const verify = {
        token: token
      };
      const apiUrl = 'http://127.0.0.1:8000/v1/api/partner/verify';
      console.log(apiUrl)
      await axios.post(apiUrl, verify);
      return true;
    } catch (error) {
      const refresh = localStorage.getItem('refresh');

      if (refresh !== null) {
        const refreshObj = {
          refresh: refresh
        };
        const apiRefresh = 'http://127.0.0.1:8000/v1/api/partner/refresh';

        try {
          const response = await axios.post(apiRefresh, refreshObj);
          commit('login', response.data);
          return true;
        } catch (error) {
          localStorage.setItem('refresh', null);
          localStorage.setItem('token', null);
          console.error(error);
          return false;
        }
      } else {
        console.error('No refresh found in localStorage');
        localStorage.setItem('refresh', null);
        localStorage.setItem('token', null);
        return false;
      }
    }
  } else {
    console.error('No token found in localStorage');
    localStorage.setItem('refresh', null);
    localStorage.setItem('token', null);
    return false;
  }
}


const store = createStore({
  state: {
    token: localStorage.getItem('token'),
    refresh: localStorage.getItem('refresh'),
    partner_token: localStorage.getItem('partner_token'),
    admin_token: localStorage.getItem('admin_token'),
    isLoggedIn: false,
    carrito: 0
  },
  mutations: {
    login(state, data) {
      state.isLoggedIn = true;
      if (data.access){
        console.log("token metido")
        state.partner_token = data.access;
        localStorage.setItem('partner_token', data.access)
      }
      if (data.refresh && data.refresh != null){
        console.log("refresh metido")
        state.refresh = data.refresh
        localStorage.setItem('refresh', data.refresh)
      }
    },
    loginAdmin(state, data) {
      state.isLoggedIn = true;
      if (data.access){
        console.log("token metido")
        state.admin_token = data.access;
        localStorage.setItem('admin_token', data.access)
      }
    },
    loginTable(state, data) {
      state.isLoggedIn = true;
      if (data.access){
        console.log("token metido")
        state.token = data.access;
        localStorage.setItem('token', data.access)
      }
    },
    logout(state) {
      localStorage.setItem('refresh', null);
      localStorage.setItem('token', null);
      localStorage.setItem('partner_token', null);
      state.isLoggedIn = false;
    },
    setLoggedIn(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn;
    }
  },
  actions: {
    async initialice({ commit }) {
      const isLoggedIn = await ComprobarToken(commit);
      commit('setLoggedIn', isLoggedIn);
    },
    setToken({ commit }, data) {
      commit('login', data);
    },
    setTokenAdmin({ commit }, data) {
      commit('loginAdmin', data);
    },
    setTokenTable({ commit }, data) {
      commit('loginTable', data);
    },
    TokenBueno({ commit }){
      commit('TokenBueno');
    }
  }
});

export default store;
