<template>
  <div class="container-fluid p-4">
    <h1 class="text-center fw-normal">Mesas</h1>
    <div class="row mb-5 pb-5">
      <div class="row" style="width: 100vw !important;" v-if="this.tables.length == 0">
        <img class="col-xl-4 col-lg-5 col-md-6 col-sm-8 col-11 mx-auto" src="../assets/not_plates_founds.png">
        <h5 class="text-center mt-1">No hay mesas</h5>
      </div>
      <div v-for="table in tables" :key="table.id" class="col-12 col-sm-6 col-lg-4 mb-4">
        <div class="card shadow-sm d-flex flex-column justify-content-between h-100">
          <div class="d-flex flex-column justify-content-between pt-3">
            <p class="card-title text-center mb-1 fw-bold fs-4">Mesa {{ table.number }}</p>
          </div>
          <p class="btn btn-warning col-8 mx-auto mt-2 text-white fw-semibold" style="background-color:darkorange" @click="AccederMesa(table.id)">
            Acceder
          </p>
        </div>
      </div>
    </div>
    <SpinnerComponent id="spinner"></SpinnerComponent>
    <div style="background-color: rgba(139, 139, 139, 0.5);position: fixed; top:0; left: 0; height: 100vh; width: 100vw; z-index: 100; display: none;" @click.self="closeModal" id="modal">
      <div style="height: 95vh;" class="bg-white mx-auto mt-4" id="modal-inner">
        <div class="modal-content p-4 rounded-4 position-relative">
          <button type="button" class="btn-close position-absolute top-0 end-0 m-3" @click="closeModal" aria-label="Close"></button>
          <iframe id="iframe" src="" class="mx-auto border" style="height: 90vh; width: 90%;"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SpinnerComponent from '../components/SpinnerComponent.vue'
  import axios from 'axios';
  import { mapState } from 'vuex';
  
  export default {
    components: {
      SpinnerComponent
    },
    data() {
      return {
        tables: [],
        first_charge: true,
        showModal: false
      };
    },
    computed: {
      ...mapState(['partner_token'])
    },
    methods: {
      closeModal() {
        document.getElementById("modal").style.display = "none"
        document.querySelector('footer').style.display = "block"
        document.getElementById('iframe').src = ""
      },
      AccederMesa(table_id){
        document.getElementById('iframe').src = "http://localhost:8080/LoginQr/" + table_id
        document.getElementById("modal").style.display = "block"
        document.querySelector('footer').style.display = "none"
      },
      async fetchMesas() {
        const token = this.partner_token; // Suponiendo que tienes el token almacenado en Vuex
        const url = 'http://127.0.0.1:8000/v1/api/app/PanelTables';
        try {
          const response = await axios.get(url, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });

          // Maneja la respuesta
          console.log(response.data);
          this.tables = response.data.tables.sort((a, b) => a.number - b.number);
          if(this.first_charge){
            document.getElementById("spinner").classList.add("d-none")
            this.first_charge=false
          }
        } catch (error) {
          // Maneja el error
          this.tables = []
          document.getElementById("spinner").classList.add("d-none")
          console.error('Error fetching tables:', error);
        }
      },
      startPolling() {
          this.fetchMesas();
          this.pollingInterval = setInterval(this.fetchMesas, 3000); // Cada 5 segundos
      },
      stopPolling() {
        clearInterval(this.pollingInterval);
      },
    },
    created() {
      this.startPolling();
    },
    beforeUnmount() {
      this.stopPolling();
    }
  };
</script>

<style scoped>
  .categories-btn{
    background-color: #f1f5f5;
  }

  .card-title {
    font-size: 1.25rem;
    word-wrap: break-word;
  }

  .card {
    height: 100%;
  }

  .card-footer {
    margin-top: auto;
  }

  .btn-group .btn.active {
    background-color: #f47700;
    color: white;
  }

  .btn {
    white-space: nowrap;
  }

  .custom-btn:focus, 
  .custom-btn:active, 
  .custom-btn:hover {
    background-color: transparent;
    border-color: #f47700;
    color: #f47700;
    box-shadow: none;
  }

  #modal-inner{
    width: 40vw;
  }

  @media (max-width: 992px) {
    #modal-inner{
      width: 50vw;
    }
  }

  @media (max-width: 768px) {
    #modal-inner{
      width: 70vw;
    }
  }

  @media (max-width: 576px) {
    .btn {
      font-size: 0.9rem;
      padding: 0.25rem 0.5rem;
    }

    .card-title {
      font-size: 1rem;
    }
    #modal-inner{
      width: 85vw;
    }
  }

  @media (max-width: 430px) {
    #modal-inner{
      width: 100vw;
    }
    #modal-inner iframe{
      width: 100vw !important;
    }
    .modal-content{
      padding: 0px !important;
    }
  }
</style>