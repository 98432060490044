<template>
    <div>
        Unauthorized
    </div>
  </template>
  
  <script>

  export default {
    name: 'UnAuthorized',
    data: function() {
          return {
            
        }
      },
    components: {
        
    },
    computed:{
        
    },
    methods: {
        
    },
    mounted(){
      //this.$store.commit('logout');
    }
  }
  
  </script>
  
  <style scoped>
  
  
  </style>