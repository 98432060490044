<template>
  <div>
      <h1 class="text-center mt-4 fw-normal">Cuenta</h1>
      <div v-if="plates.length>0">
        <div class="px-3 mt-4" id="div-platos">
          <div class="row m-0 shadow rounded-4 py-3 mb-3 w-100" v-for="(plate, index) in plates" :key="index">
            <div class="col-5">
              <img :src="plate.photo ? plate.photo : noPhoto" class="rounded-4" style="object-fit: cover; width: 100%; height: 100px" alt="...">
            </div>
            <div class="col-7">
              <p class="mt-1 mb-1" style="font-size: 1rem;">{{ plate.name }}</p>
              <p class="mt-1 mb-1" style="font-size: 0.7rem; color: #F47700;">{{ plate.description }}</p>
              <div class="row d-flex justify-content-end mt-4">
                <span class="w-auto fw-medium" style="font-size: 0.9rem;">
                  {{ plate.price }}€ X {{ plate.amount }} = {{ plate.price * plate.amount }}€
                </span>
              </div>
            </div>
          </div>
        </div>
        <p class="text-end px-4 fw-bold fs-2">{{ total_price }}€</p>
        <div class="px-3 d-none" id="errorMessage">
          <div class="alert alert-danger text-center" role="alert">
            ¡No puedes pedir la cuenta si sigues esperando platos!
          </div>
        </div>
        <div class="w-100 d-flex justify-content-center mb-5 pb-3">
          <p @click="PedirCuenta()" id="finalize-order-button" class="px-5 shadow" :class="{'disabled': ['PENDIENTE', 'EN CURSO'].includes(this.state)}">
            Pedir la cuenta
          </p>
        </div>
      </div>
      <div v-else class="h-75 d-flex align-items-center justify-content-center">
        <div class="row">
          <img class="col-xl-4 col-lg-5 col-md-7 col-sm-9 col-11 mx-auto" src="../../src/assets/empty-cuenta.png"/>
          <h2 class="text-center mt-3 px-5">No es posible pedir la cuenta</h2>
        </div>
      </div>
      <SpinnerComponent id="spinner"></SpinnerComponent>
      <PedirCuenta></PedirCuenta>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import SpinnerComponent from '../components/SpinnerComponent.vue'
import PedirCuenta from '@/components/PedirCuenta.vue'
import noPhoto from './../assets/no_plate.png';

export default {
  name: 'HomeA',
  data: function() {
        return {
          state: null,
          total_price: null,
          plates: [],
          first_charge: true,
          noPhoto
        }
    },
  components: {
    SpinnerComponent,
    PedirCuenta
  },
  computed:{
      ...mapState(['token']),
  },
  methods: {
    logout() {
      this.$store.commit('logout');
    },
    async PedirCuenta(){
      let errorDiv = document.getElementById("errorMessage")
      
      if (!['PENDIENTE', 'EN CURSO'].includes(this.state)){
        console.log("Pedir la cuenta")
        document.getElementById("order_bill").classList.remove("d-none")
        if (!errorDiv.classList.contains("d-none")){
          errorDiv.classList.add("d-none")
        }
      }else{
        if (errorDiv.classList.contains("d-none")){
          errorDiv.classList.remove("d-none")
        }
        
      }
    },
    async fetchGetCuenta() {
      const token = this.token; // Suponiendo que tienes el token almacenado en Vuex
      const url = 'http://127.0.0.1:8000/v1/api/app/Bill';
      try {
        const response = await axios.get(url, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        // Maneja la respuesta
        console.log(response.data);
        this.plates = response.data.plates
        this.total_price = response.data.price
        this.state = response.data.state
        if(this.first_charge){
          document.getElementById("spinner").classList.add("d-none")
          this.first_charge=false
        }
      } catch (error) {
        // Maneja el error
        this.plates = []
        console.error('Error fetching my orders:', error);
        document.getElementById("spinner").classList.add("d-none")
      }
    },
    startPolling() {
      this.fetchGetCuenta();
      this.pollingInterval = setInterval(this.fetchGetCuenta, 5000); // Cada 5 segundos
    },
    stopPolling() {
      clearInterval(this.pollingInterval);
    },
  },
  created() {
    this.startPolling();
  },
  beforeUnmount() {
    this.stopPolling();
  }
}

</script>

<style scoped>
    #div-platos p{
      max-height: 59px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .disabled{
      background-color: rgb(178, 178, 178) !important;
    }
</style>