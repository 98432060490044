<template>
    <div v-if="showModal" class="modal fade show d-block z-3" tabindex="-1">
        <div @click="closeModal()" class="h-100 w-100 position-fixed z-1"></div>
        <div class="modal-dialog d-flex align-items-center justify-content-center z-2 mt-5">
            <div class="modal-content" style="width:90%">
                <div class="d-flex justify-content-center align-items-center mt-3 mb-3">
                    <h3 class="modal-title">Nuevo Plato</h3>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="confirmAdd">
                        <div class="row">
                            <span id="span-error" class="text-danger text-center pb-3"></span>
                            <div class="col-md-6 mb-5">
                                <div class="form-group">
                                    <b for="dish-name">Nombre:</b><br />
                                    <input type="text" id="dish-name" v-model="newDish.name" class="form-control shadow rounded-3" placeholder="Ejemplo: Solomillo a la plancha" />
                                </div>
                            </div>
                            <div class="col-md-6 mb-5">
                                <div class="form-group">
                                    <b for="dish-category">Categoría:</b><br />
                                    <select id="dish-category" v-model="newDish.category" class="form-control shadow rounded-3">
                                        <option v-for="category in categories" :key="category.id" :value="category.id">{{ category.name }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-12 mb-5">
                                <div class="form-group">
                                    <b for="dish-description">Descripción:</b><br />
                                    <textarea id="dish-description" v-model="newDish.description" class="form-control shadow rounded-3" placeholder="Solomillo a la plancha con patatas al horno y salsa ranchera"></textarea>
                                </div>
                            </div>
                            <div class="col-md-12 mb-4">
                                <div class="form-group">
                                    <b for="dish-description">Precio:</b><br />
                                    <input type="number" step="0.01" v-model="newDish.price" class="form-control shadow rounded-3"/>
                                </div>
                            </div>
                            <div class="col-md-12 mb-2 row m-0">
                                <div class="form-check form-switch mx-auto d-flex justify-content-center">
                                    <input class="form-check-input fs-5 me-3 " v-model="newDish.available" type="checkbox" role="switch" id="flexSwitchCheckDefault">
                                    <h6 class="form-check-label mt-1 fw-bold" for="flexSwitchCheckDefault">Disponible</h6>
                                </div>
                            </div>
                            <div class="col-md-12 mb-5">
                                <div class="form-group">
                                    <b for="dish-image">Imagen:</b><br />
                                    <input type="file" id="dish-image" @change="handleFileUpload" class="form-control shadow rounded-3" />
                                    <img v-if="imagePreview" :src="imagePreview" alt="Imagen del plato" class="img-fluid mt-3" />
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer align-items-center justify-content-center border-none">
                            <button type="submit" class="btn btn-add text-white px-4 rounded-3" style="background-color: #f47700">Añadir</button>
                            <button type="button" @click="closeModal" class="btn btn-danger px-4 rounded-3">Cancelar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
  
  <script>
    export default {
      props: {
          showModal: {
              type: Boolean,
              required: true,
          },
          categorias: {
          type: [],
          default: () => ({})
        },
      },
      data() {
          return {
            newDish: {
                  name: '',
                  category: "",
                  price: "",
                  description: "",
                  photo: null,
                  available:false
              },
              categories: [],
              imagePreview: null,
          };
      },
      methods: {
          closeModal() {
              this.$emit('close-modal');
          },
          handleFileUpload(event) {
              const file = event.target.files[0];
              if (file) {
                  const reader = new FileReader();
                  reader.onload = (e) => {
                      this.imagePreview = e.target.result;
                      this.newDish.photo = e.target.result;
                  };
                  reader.readAsDataURL(file);
              }
          },
          confirmAdd() {
              if (this.newDish.name== "" || this.newDish.category== "" || this.newDish.price== "" || this.newDish.description== "") {
                document.getElementById("span-error").textContent = "Debes rellenar todos los campos para continuar"
                return;
              }
              this.$emit('confirm-add', { ...this.newDish });
              this.newDish.name = '';
              this.newDish.category = 1;
              this.newDish.photo = null;
              this.imagePreview = null;
              this.closeModal();
          },
      },
      watch: {
        categorias(categories){
          this.categories = { ...categories };
        }
      }
    };
  </script>
  
  <style scoped>
    .modal {
      background-color: rgba(139, 139, 139, 0.5);
    }
    .modal-dialog {
      min-height: 90vh;
    }
  </style>  