<template>
    <div v-if="showModal" class="modal fade show d-block z-3" tabindex="-1">
        <div @click="closeModal()" class="h-100 w-100 position-fixed z-1"></div>
        <div class="modal-dialog d-flex align-items-center justify-content-center z-2">
            <div class="modal-content" style="width:90%">
                <div class="d-flex justify-content-center align-items-center mt-3 mb-3">
                    <h3 class="modal-title">Nueva Categoría</h3>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="confirmAdd">
                        <div class="row d-flex align-items-center justify-content-center">
                            <span id="span-error" class="text-danger text-center pb-3"></span>
                            <div class="col-md-8 mb-4">
                                <div class="form-group">
                                    <b for="category-description">Nombre:</b><br />
                                    <input type="text" id="category-description" v-model="newCategory.name" class="form-control shadow rounded-3" placeholder="Ejemplo: Carnes" />
                                </div>
                            </div>
                            <div class="col-md-8 mb-4">
                                <div class="form-group">
                                    <b for="dish-description">Posición para el cliente:</b><br />
                                    <input type="number" step="1" v-model="newCategory.position" class="form-control shadow rounded-3" placeholder="1" />
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer align-items-center justify-content-center border-none">
                            <button type="submit" class="btn btn-add text-white px-4 rounded-3" style="background-color: #f47700">Añadir</button>
                            <button type="button" @click="closeModal" class="btn btn-danger px-4 rounded-3">Cancelar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
  
  <script>
    export default {
      props: {
          showModal: {
              type: Boolean,
              required: true,
          },
      },
      data() {
        return {
            newCategory: {
                name: '',
                position: 0
            }
        };
      },
      methods: {
          closeModal() {
              this.$emit('close-modal');
          },
          confirmAdd() {
            console.log(this.newCategory)
              if (this.newCategory.name == "" || this.newCategory.position == "") {
                document.getElementById("span-error").textContent = "Debes rellenar todos los campos para continuar"
                return;
              }
              this.$emit('confirm-add', { ...this.newCategory });
              this.newCategory.name = '';
              this.closeModal();
          },
      },
    };
  </script>
  
  <style scoped>
    .modal {
      background-color: rgba(139, 139, 139, 0.5);
    }
    .modal-dialog {
      min-height: 90vh;
    }
  </style>  