<template>
  <div class="mt-5 pt-3 px-5">
    <div class="pb-5">
      <h2 class="text-center mb-4" >Incidencias</h2>
      <div class="table-responsive" v-if="incidencias.length > 0">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Descripción</th>
              <th scope="col">Imagen</th>
              <th scope="col">Estado</th>
              <th scope="col">Respuesta</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="incidence in incidencias" :key="incidence.id">
              <td>{{incidence.description}}</td>
              <td><img :src="incidence.photo" style="height: 70px;" @click="this.image_amplied = incidence.photo"/></td>
              <td :class="{'text-danger': incidence.state === 'Pendiente'}" class="fw-bold">{{ incidence.state.toUpperCase() }}</td>
              <td>{{incidence.response}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="row m-0 my-4">
        <img src="./../assets/0_Incidencias.png" class="col-xl-4 col-lg-6 col-md-8 col-sm-10 col-12 mx-auto">
        <h3 class="text-center mt-3">No has registrado ningun incidencia.</h3>
      </div>
      <div class="row m-0 mt-4">
        <button @click="this.sending_incidence=true" class="btn fw-semibold text-white mx-auto col-xl-2 col-md-4 col-sm-6 col-8" style="background-color: #ff7a00">Crear Incidencia</button>
      </div>
    </div>
    <div v-if="sending_incidence" class="modal fade show d-block z-3" tabindex="-1">
      <div @click="closeModal()" class="h-100 w-100 position-fixed z-1"></div>
      <div class="modal-dialog d-flex align-items-center justify-content-center z-2">
          <div class="modal-content p-4" style="width:90%">
            <div v-if="!incidenceSent">
              <div class="d-flex justify-content-center align-items-center mt-2 mb-3">
                  <h3 class="modal-title">Nueva Incidencia</h3>
              </div>
              <form @submit.prevent="enviarFormulario">
                <div class="mb-4">
                  <label for="error" class="form-label">Descripción del error <span class="text-danger">*</span></label>
                  <textarea class="form-control" id="error" v-model="descriptionError" rows="4" placeholder="Describe el error que encontraste"></textarea>
                </div>
                <div class="mb-4">
                  <label for="file" class="form-label">Adjuntar archivo</label>
                  <input type="file" class="form-control" id="file" @change="handleFileUpload" />
                </div>
                <div v-if="errorMessage" class="alert alert-danger">
                  {{ errorMessage }}
                </div>
                <div class="d-grid gap-2">
                  <button type="submit" class="btn btn-primary btn-block text-white rounded-3 border-none" style="background-color: #ff7a00;">Enviar</button>
                </div>
              </form>
            </div>
            <div v-else>
              <div  class="text-center image-background mt-4" @click.self="reiniciarFormulario">
                <div class="col-12">
                  <div class="row w-100 d-flex flex-column align-items-center justify-content-center">
                    <h3 class="text-success mb-3">¡Incidencia enviada con éxito!</h3>
                    <p>Contactaremos contigo lo antes posible para solucionarla.</p>
                    <img src="@/assets/tick.gif" alt="Incidencia enviada" class="img-fluid w-25" />
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
  </div>
    <div v-if="image_amplied" class="modal fade show d-block z-3" tabindex="-1">
        <div @click="this.image_amplied = null" class="h-100 w-100 position-fixed z-1"></div>
        <div class="modal-dialog d-flex align-items-center justify-content-center z-2">
            <div class="modal-content p-1">
              <img :src="image_amplied" class="w-100"/>
            </div>
        </div>
    </div>
    <SpinnerComponent id="spinner"></SpinnerComponent>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import axios from 'axios';
import SpinnerComponent from '../components/SpinnerComponent.vue'
import { v4 as uuidv4 } from 'uuid';

  export default {
    data() {
      return {
        descriptionError: "",
        attachment: null,
        errorMessage: "",
        incidenceSent: false,
        incidencias: [],
        sending_incidence: false,
        image_amplied: null
      };
    },
    components: {
      SpinnerComponent
    },
    computed: {
      ...mapState(['partner_token'])
    },
    methods: {
      handleFileUpload(event) {
        const file = event.target.files[0];
          if (file) {
              const reader = new FileReader();
              reader.onload = (event) => {
                this.attachment = event.target.result;
              };
              reader.readAsDataURL(file);
          }
      },
      closeModal() {
        this.sending_incidence=false
      },
      async enviarFormulario() {
        if (!this.descriptionError) {
          this.errorMessage = "Debes poner un nombre y una descripción";
          return;
        }

        console.log("FOTO:  ")
        console.log(this.attachment)

        let new_incidence = {
            id: String(uuidv4()),
            description: this.descriptionError,
            photo: this.attachment
        }
        const url = 'http://127.0.0.1:8000/v1/api/app/incidenceCreate';
        try {
            const response = await axios.post(url, new_incidence, {
            headers: {
                'Authorization': `Bearer ${this.partner_token}`
            }
            });
            console.log(response.data);
            this.descriptionError = "";
            this.attachment = null;
            this.errorMessage = "";
            document.getElementById("file").value = null;
            this.incidenceSent= true
            setTimeout(() => {
              this.incidenceSent = false;
              this.sending_incidence = false;
              this.getIncidencias()
            }, 3000);
        } catch (error) {
            console.error('Error fetching shopping cart:', error);
        }

        
      },
      reiniciarFormulario() {
        this.incidenceSent = false;
        this.sending_incidence = false;
      },
      async getIncidencias(){
        const token = this.partner_token; // Suponiendo que tienes el token almacenado en Vuex
        const url = 'http://127.0.0.1:8000/v1/api/app/getIncidences';
        try {
            const response = await axios.get(url, {
                headers: {
                'Authorization': `Bearer ${token}`
                }
            });

            // Maneja la respuesta
            console.log(response.data);
            this.incidencias = response.data;
            document.getElementById("spinner").classList.add("d-none")
        } catch (error) {
            // Maneja el error
            this.incidencias = []
            document.getElementById("spinner").classList.add("d-none")
            console.error('Error fetching tables:', error);
        }
      }
    },
    created() {
        this.getIncidencias()
    },
    
  };
</script>

<style scoped>
.modal {
    background: rgba(139, 139, 139, 0.5);
  }
  .modal-dialog {
    min-height: 90vh;
  }
</style>