<template>
    <div class="mt-5">
        <div class="row">
          <img src="../../src/assets/EWU_Negro.png" class="col-md-3 col-sm-5 col-6 mx-auto"/> 
        </div>
        <div class="row col-md-8 mx-auto m-0 mt-4">
          <h5>Restaurante</h5>
          <div class="row m-0">
              <select class="form-select" @change="handleSelectRestaurant" v-model="restaurant_selected">
                <option selected value="">Elige un restaurante</option>
                <option v-for="restaurant in restaurants" :key="restaurant.id" :value="restaurant.id">{{ restaurant.name }}</option>
              </select>
          </div>
        </div>
        <div class="row col-md-8 mx-auto m-0 mt-4">
          <h5>Partners</h5>
          <div class="row">
            <div class="col-9">
              <select class="form-select" v-model="partner_selected">
                <option v-for="partner in partners" :key="partner.id" :value="partner.id">{{ partner.username }}</option>
              </select>
            </div>
            <button class="btn btn-dark col-3" @click="Login(partner_selected)">Entrar</button>
          </div>
        </div>
        <div class="row col-md-8 mx-auto m-0 mt-4">
          <h5>Camareros</h5>
          <div class="row">
            <div class="col-9">
              <select class="form-select" v-model="waiter_selected">
                <option v-for="waiter in waiters" :key="waiter.id" :value="waiter.id">{{ waiter.username }}</option>
              </select>
            </div>
            <button class="btn btn-dark col-3" @click="Login(waiter_selected)">Entrar</button>
          </div>
        </div>
        <div class="row col-md-8 mx-auto m-0 mt-4">
          <h5>Mesas</h5>
          <div class="row">
            <div class="col-9">
              <select class="form-select" v-model="table_selected">
                <option v-for="table in tables" :key="table.id" :value="table.id">{{ table.number }}</option>
              </select>
            </div>
            <button class="btn btn-dark col-3" @click="Login(table_selected)">Entrar</button>
          </div>
        </div>
        <SpinnerComponent id="spinner"></SpinnerComponent>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import SpinnerComponent from '../components/SpinnerComponent.vue'
  import { mapState } from 'vuex';
  import router from '@/router';

  export default {
    name: 'UnAuthorized',
    data: function() {
          return {
            restaurants: [],
            partners: [],
            waiters: [],
            tables: [],
            restaurant_selected : "",
            partner_selected: "",
            waiter_selected: "",
            table_selected: ""
        }
      },
    components: {
      SpinnerComponent
    },
    computed:{
      ...mapState(['admin_token'])
    },
    methods: {
      async handleSelectRestaurant(){
        if (this.restaurant_selected){
          document.getElementById("spinner").classList.remove("d-none")
          const token = this.admin_token; // Suponiendo que tienes el token almacenado en Vuex
          console.log(token)
          const url = 'http://127.0.0.1:8000/v1/api/app/users_by_restaurant?restaurant_id=' + this.restaurant_selected;
          try {
              const response = await axios.get(url, {
                  headers: {
                  'Authorization': `Bearer ${token}`
                  }
              });

              // Maneja la respuesta
              console.log(response.data);
              this.partners = response.data.partners
              this.waiters = response.data.waiters
              this.tables = response.data.tables
              document.getElementById("spinner").classList.add("d-none")
          } catch (error) {
              // Maneja el error
              document.getElementById("spinner").classList.add("d-none")
              console.error('Error fetching tables:', error);
          }
        }
      },
      async Login(id){
        document.getElementById("spinner").classList.remove("d-none")
        const token = this.admin_token; // Suponiendo que tienes el token almacenado en Vuex
        console.log(token)
        const url = 'http://127.0.0.1:8000/v1/api/app/loginAdmin?id=' + id;
        try {
            const response = await axios.post(url, {}, {
                headers: {
                'Authorization': `Bearer ${token}`
                }
            });

            this.$store.dispatch('setToken', response.data);
            
            if (response.data.user_partner){
              window.open(this.$router.resolve({ name: "PartnerSettings" }).href, '_blank');
            }else if (response.data.user_partner == false){
              window.open(this.$router.resolve({ name: "PanelComandas" }).href, '_blank');
            }else{
              this.$store.dispatch('setTokenTable', response.data);
              window.open(this.$router.resolve({ name: "Home" }).href, '_blank');
            }
            document.getElementById("spinner").classList.add("d-none")
        } catch (error) {
            // Maneja el error
            document.getElementById("spinner").classList.add("d-none")
            console.error('Error fetching tables:', error);
        }
      }
    },
    async mounted(){
      const token = this.admin_token; // Suponiendo que tienes el token almacenado en Vuex
      console.log(token)
      const url = 'http://127.0.0.1:8000/v1/api/app/restaurants';
      try {
          const response = await axios.get(url, {
              headers: {
              'Authorization': `Bearer ${token}`
              }
          });

          // Maneja la respuesta
          console.log(response.data);
          this.restaurants = response.data
          document.getElementById("spinner").classList.add("d-none")
      } catch (error) {
          // Maneja el error
          document.getElementById("spinner").classList.add("d-none")
          console.error('Error fetching tables:', error);
          router.push({ name: "Login" });
      }
    }
  }
  
  </script>
  
  <style scoped>
  
  
  </style>