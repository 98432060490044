<template>
    <div id="waiter_call" class="d-none">
      <div @click="Close()" class="h-100 w-100 position-fixed z-1">
      </div>
      <div class="bg-white expanded rounded-4 p-4 z-2">
        <div v-if="initial">
          <h2 class="text-center mb-3">¿Confirmar llamada al camarero?</h2>
          <p class="fw-semibold px-2">Al presionar "Confirmar", un camarero será notificado y vendrá a asistirte en breve.</p>
          <div class="d-flex justify-content-center mt-4">
            <p class="btn btn-danger px-4" @click="Close()">Cancelar</p>
            <p class="btn text-white ms-4 px-4" @click="LlamarCamarero()" style="background-color: #F47700;">Confirmar</p>
          </div>
        </div>
        <div v-else>
          <h4 class="text-center mb-4">Ya hemos notificado al camarero</h4>
          <h6 class="mt-4"><i class="fa-solid fa-circle-check text-success"></i> Acudirá en breves a su mesa</h6>
        </div>
      </div>
      
    </div>
  </template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';

export default {
  name: 'LlamadaCamarero',
  data: function() {
      return {
        initial: true
      }
    },
  components: {
    
  },
  computed:{
    ...mapState(['token'])
  },
  methods: {
    Close(){
      document.getElementById("waiter_call").classList.add("d-none")
    },
    async LlamarCamarero(){
      let url = 'http://127.0.0.1:8000/v1/api/app/callWaiter'
      try {
        const response = await axios.post(url, {}, {
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        });
        console.log(response.data);
        this.initial = false

        setTimeout(() => {
          document.getElementById("waiter_call").classList.add("d-none")
          this.initial = true
        }, 3000);
      } catch (error) {
        console.error('Error fetching shopping cart:', error);
      }
    }
  }
}

</script>
  
  <style scoped>
  /* Estilo para el contenedor del spinner */
  #waiter_call {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(139, 139, 139, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000 !important;
    transition: all 3s ease
  }

  .expanded{
    width: 90%;
  }
  
  </style>
  