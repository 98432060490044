<template>
  <div class="pb-0">
    <header class="fixed-top" style="border-bottom: 1px solid #b8b3b3; cursor: pointer; width: 99.3vw;">
      <nav class="navbar navbar-expand-sm bg-white" style="min-height: 9vh">
        <div class="container-fluid">
          <img src="../assets/EWU_Negro.png" class="navbar-brand" style="height: 7vh; padding-left: 10vw" @click="scrollToTop"/>
          <button class="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
            <span class="fa-solid fa-bars fs-1 " style="color: #270a47"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarScroll">
            <div class="me-auto"></div>
            <ul class="navbar-nav my-2 my-lg-0 navbar-nav-scroll" style="--bs-scroll-height: 100px; padding-right: 5vw">
              <li class="nav-item fw-semibold">
                <a class="nav-link" aria-current="page" @click="scrollToTop">Inicio</a>
              </li>
              <li class="nav-item fw-semibold">
                <a class="nav-link" href="#utilidades">Utilidades</a>
              </li>
              <li class="nav-item fw-semibold">
                <a class="nav-link" href="#pricing">Pricing</a>
              </li>
              <li class="nav-item fw-semibold">
                <a class="nav-link" href="#contacto">Contacto</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>

    <!-- boton volver arriba-->
    <i v-if="showButton" class="fa-solid fa-arrow-up fs-5 subir" style="color: white;" @click="scrollToTop"></i>

    <!--pantalla 1: inicio-->
    <div class="row mx-0 d-flex align-items-center justify-content-center screen" id="inicio">
      <div class="col-lg-4 col-sm-5">
        <h1 class="mb-4 w-100 text-sm-start text-center" style="font-size: 4.5rem">
          Una nueva forma de <span style="color: #f47700">optimizar</span> tu restaurante
        </h1>
        <h2 class="w-100 text-sm-start text-center" style="font-size: 2.2rem">La revolución de la restauración</h2>
      </div>

      <img src="../assets/screen1_landing.png" class="col-lg-4 col-md-5 col-sm-6 col-11 mb-sm-0 mb-5" />
      
    </div>

    <!--pantalla 2: utilidades-->
    <div class="screen pt-5" id="utilidades">
      <h1 class="pt-5 text-center text-black">
        Utilidades de
        <img src="../assets/logo_Negro.png" style="width: 12vh; color: black" />
      </h1>

      <!--cajas-->
      <div class="row mx-0 justify-content-evenly mt-5">
        <!--cliente-->
        <div class="cajas col-md-3 align-self-center">
          <h1 class="mb-3">Cliente 01</h1>
          <h3>Pedir rápidamente desde el móvil</h3>
          <div class="d-flex justify-content-end align-items-end mt-4">
            <img src="../assets/movil_gris_caja.png" style="width: 6vw" class="utilidades_movil_img" />
          </div>
        </div>

        <!--camarero-->
        <div class="cajas col-md-3 align-self-center">
          <h1 class="mb-3">Camarero 02</h1>
          <h3>Gestión de pedidos y mesas</h3>
          <div class="d-flex justify-content-end align-items-end mt-5">
            <img src="../assets/tablet_gris_caja.png" style="width: 12vw" class="utilidades_pc_img pb-1" />
          </div>
        </div>

        <!--admin-->
        <div class="cajas col-md-3 align-self-center">
          <h1 class="mb-3">Admin 03</h1>
          <h3>Gestión y ajustes del restaurante</h3>
          <div class="d-flex justify-content-end align-items-end mt-5">
            <img src="../assets/ordenador_gris_caja.png" style="width: 12vw" class="utilidades_pc_img pb-1" />
          </div>
        </div>
      </div>
    </div>

    <!--pantalla 3: carousel-->
    <div id="carouselExampleInterval" class="carousel slide screen" data-bs-ride="carousel" >
      <!-- Indicadores de navegación del carrusel -->
      <div class="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
      </div>
      <div class="carousel-inner justify-content-center " style="font-size: 1rem !important;">
        <div class="carousel-item active justify-content-center" data-bs-interval="5000" id="perfilCliente">
            <h1 class="text-center pt-3" style="height: 15%; color: #4e2780;"><i>Perfil Cliente</i></h1>
            <div class="row mx-0 col-12 m-0 align-items-center justify-content-center" style="height: 80%; overflow-y: scroll;">
              <div class="col-md-3  align-self-center ">
                <ul class="list-unstyled">
                  <li class="row mb-2">
                    <i class="fa-solid fa-qrcode fs-2 col-3" style="color: #f47700;"></i>
                    <p class="text-start col-9" style="color: #4e2780;">
                      <span class="fw-bold">Carta</span>
                      <br />
                      Los comensales escanean un QR en la mesa desde el que pueden visualizar la carta del restaurante.
                    </p>
                  </li>
                  <li class="row mb-2">
                    <i class="fa-solid fa-bell-concierge fs-2 col-3" style="color: #f47700;"></i>
                    <p class="text-start col-9" style="color: #4e2780;">
                      <span class="fw-bold">Camarero</span>
                      <br />
                      Pueden llamar al camarero desde la app para que se acerque a la mesa.
                    </p>
                  </li>
                  <li class="row mb-2">
                    <i class="fa-solid fa-cart-shopping fs-2 col-3" style="color: #f47700;"></i>
                    <p class="text-start col-9" style="color: #4e2780;">
                      <span class="fw-bold">Carrito</span>
                      <br />
                      Los comensales pueden añadir todos productos que deseen al carrito y ver el importe total antes de pedir.
                    </p>
                  </li>
                </ul>
              </div>
              <div class="col-md-3 align-self-center d-flex justify-content-center mb-4">
                <img src="../assets/carousel_diapositiva_1.png" class="img-fluid" />
              </div>
              <div class="col-md-3 align-self-center">
                <ul class="list-unstyled">
                  <li class="row">
                    <p class="text-end col-9" style="color: #4e2780;">
                      <span class="fw-bold">Pedidos</span>
                      <br />
                      Los comensales pueden pedir todos los platos y bebidas de manera autónoma desde la app.
                    </p>
                    <i class="fa-solid fa-clipboard-list fs-2 col-3" style="color: #f47700;"></i>
                  </li>
                  <li class="row mb-2">
                    <p class="text-end col-9" style="color: #4e2780;">
                      <span class="fw-bold">Historial</span>
                      <br />
                      Los comensales pueden visualizar el historal de los pedidos que han realizado.
                    </p>
                    <i class="fa-solid fa-clock-rotate-left fs-2 col-3" style="color: #f47700;"></i>
                  </li>
                  <li class="row">
                    <p class="text-end col-9" style="color: #4e2780;">
                      <span class="fw-bold">Cuenta</span>
                      <br />
                      Pueden solicitar la cuenta y esto le manda un aviso a los camareros.
                    </p>
                    <i class="fa-solid fa-receipt fs-2 col-3" style="color: #f47700;"></i>
                  </li>
                </ul>
              </div>
          </div>
        </div>
        <div class="carousel-item" data-bs-interval="5000" id="perfilCamarero">
          <h1 class="text-center pt-3" style="height: 15%; color: #4e2780;"><i>Perfil Camarero</i></h1>
            <div class="row align-items-center justify-content-center" style="height: 80%; overflow-y: scroll;">
              <div class="col-md-4 align-self-center">
                <ul class="list-unstyled">
                  <li class="row mb-4">
                    <i class="fa-solid fa-users fs-2 col-3" style="color: #f47700;"></i>
                    <p class="text-start col-9" style="color: #4e2780;">
                      <span class="fw-bold">Panel de Mesas</span>
                      <br />
                      Activar las mesas indicando el nº de comensales y si tienen algun <span style="color: #f47700">modo especial*</span>.
                      <br />
                      <br />
                      También los camareros pueden liberar las mesas que ya han finalizado dejandolas libres.
                    </p>
                  </li>
                  <li class="row">
                    <i class="fa-solid fa-clipboard-list fs-2 col-3" style="color: #f47700;"></i>
                    <p class="text-start col-9" style="color: #4e2780;">
                      <span class="fw-bold">Panel de Comandas</span>
                      <br />
                      Ver y gestionar los pedidos de cada mesa.
                      <br />
                      <br />
                      Y cambiar el estado del producto: pendiente, en proceso y entregado.
                    </p>
                  </li>
                </ul>
              </div>
              <div class="col-md-5 align-self-center ps-5 d-flex justify-content-center">
                <img src="../assets/carousel_diapositiva_2.png" class="w-100"/>
              </div>
            </div>
        </div>
        <div class="carousel-item" data-bs-interval="5000" id="perfilAdmin">
          <h1 class="text-center pt-3" style="height: 15%; color: #4e2780;"><i>Perfil Admin</i></h1>
          <div class="row align-items-center justify-content-evenly" style="height: 80%; overflow-y: scroll;">
            <div class="col-md-4 align-self-center d-flex justify-content-center mb-4">
              <img src="../assets/carousel_diapositiva_3.png" class="img-fluid" />
            </div>
            <div class="col-md-4 align-self-center">
              <ul class="list-unstyled">
                <li class="row">
                  <p class="text-end col-10" style="color: #4e2780;">
                    <span class="fw-bold">Ajustes</span>
                    <br />
                    Ver y gestionar la información del restaurante.
                  </p>
                  <i class="fa-solid fa-gear fs-2 col-2" style="color: #f47700;"></i>
                </li>
                <li class="row">
                  <p class="text-end col-10" style="color: #4e2780;">
                    <span class="fw-bold">Gestión de mesas</span><br />
                    Ver y gestionar mesas, indicando el número de comensales máximo y/o eliminarlas.
                  </p>
                  <i class="fa-solid fa-users fs-2 col-2" style="color: #f47700;"></i>
                </li>
                <li class="row">
                  <p class="text-end col-10" style="color: #4e2780;">
                    <span class="fw-bold">Gestión de platos y categorías</span>
                    <br />
                    Crear categorías y platos indicando el nombre, la categoría, una descripción y añadir una imagen y eliminarlos.
                  </p>
                  <i class="fa-solid fa-utensils fs-2 col-2" style="color: #f47700;"></i>
                </li>
                <li class="row">
                  <p class="text-end col-10" style="color: #4e2780;">
                    <span class="fw-bold">Gestión de camareros</span><br />
                    Crear y eliminar perfiles para los camareros indicando un nombre de usuario y contraseña.
                  </p>
                  <i class="fa-solid fa-user fs-2 col-2" style="color: #f47700;"></i>
                </li>
                <li class="row">
                  <p class="text-end col-10" style="color: #4e2780;">
                    <span class="fw-bold">Dashboard</span><br />
                    Ver las estadísticas en tiempo real del restaurante, el total de pedidos, las mesas ocupadas, el total facturado al día/mes,…
                  </p>
                  <i class="fa-solid fa-chart-line fs-2 col-2" style="color: #f47700;"></i>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>

    <!--Pantalla 4: modos especiales-->
    <div class="py-5 px-sm-5 px-0 screen" style="color: #4e2780; font-size: 1rem">
      <h1 class="px-5 fw-bold">
        Personalizaciones especiales
      </h1>
      <p class="col-md-7 px-5 text-justify mt-4" style="color: #555555;">
        Nuestra aplicación te permite crear <span style="color: #f47700" class="fw-bold">modos especiales</span> para ajustar la experiencia de tu restaurante a diferentes necesidades y situaciones. Define condiciones específicas, personaliza precios, y gestiona promociones únicas de forma sencilla desde una sola interfaz.
      </p>
      

      <!--detalles fila 1:-->
      <div class="row mx-0 mt-5 px-5">
        <div class="col-md-4">
          <i class="fa-solid fa-tag fs-2"></i>
          <p class="mt-3 mb-1 text-black fs-5">Aplica Descuentos</p>
          <p class="detallesP">Define un porcentaje de descuento para toda la carta, y excluye categorías específicas según lo necesites.</p>
        </div>

        <div class="col-md-4">
          <i class="fa-solid fa-calendar-days fs-2"></i>
          <p class="mt-3 mb-1 text-black fs-5">Horarios Personalizados</p>
          <p class="detallesP">Establece precios diferentes por franjas horarias para adultos y niños.</p>
        </div>

        <div class="col-md-4">
          <i class="fa-solid fa-utensils fs-2"></i>
          <p class="mt-3 mb-1 text-black fs-5">N° Máximo de Platos</p>
          <p class="detallesP">Limita cuántos platos puede pedir cada cliente en ese modo.</p>
        </div>
      </div>

      <!--detalles fila 2:-->
      <div class="row mx-0 mt-3 px-5">
        <div class="col-md-4">
          <i class="fa-regular fa-face-smile-beam fs-2"></i>
          <p class="mt-3 mb-1 text-black fs-5">Personaliza la Experiencia del Cliente</p>
          <p class="detallesP">Crea modos especiales para adaptarte a eventos y promociones.</p>
        </div>

        <div class="col-md-4">
          <i class="fa-solid fa-ban fs-2"></i>
          <p class="mt-3 mb-1 text-black fs-5">Gestiona las Excepciones</p>
          <p class="detallesP">Excluye categorías específicas de las promociones o descuentos.</p>
        </div>

        <div class="col-md-4">
          <i class="fa-solid fa-user-check fs-2"></i>
          <p class="mt-3 mb-1 text-black fs-5">Interfaz Sencilla e Intuitiva</p>
          <p class="detallesP">Crea y edita modos especiales fácilmente desde cualquier dispositivo (solo admin).</p>
        </div>
      </div>
      
    </div>
    
    <!--Pantalla 5: datos y beneficios -->
    <div class="screen pb-5" >
      <!--datos-->
      <div :style="divDatos">
        <div class="d-flex justify-content-center align-items-center">
          <div class="row mx-0 text-container ">
            <div class="col-xxl-2 col-sm-3 col-8 cajaDato text-center d-flex align-items-center justify-content-center mb-3">
                <div>
                  <p class="mb-0">LOS CAMAREROS DEDICAN UN</p>
                  <h1 class="mb-0">20-30%</h1>
                  <p class="mb-0">DE SU TIEMPO EN TOMAR NOTA A LOS CLIENTES</p>
                </div>
            </div>
            <div class="col-xxl-2 col-sm-3 col-8 cajaDato text-center d-flex align-items-center justify-content-center mb-3">
              <div>
                <h1 class="mb-0">30%</h1>
                <p class="mb-0">DE LOS CLIENTES REPORTAN SENTIRSE FRUSTRADOS CUANDO HAY ERRORES EN SUS PEDIDOS</p>
              </div>
              </div>
            <div class="col-xxl-2 col-sm-3 col-8 cajaDato text-center d-flex align-items-center justify-content-center">
              <div>
                <p class="mb-0">LOS CLIENTES ESPERAN DE MEDIA</p>
                <h1 class="mb-0">5-15”</h1>
                <p class="mb-0">EN SER ATENDIDOS</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--beneficios-->
      <div style="font-size: 1rem">
        <h1 class="pt-4 text-center">
          <i>Beneficios de </i> <img src="../assets/logo_Negro.png" style="width: 12vh;">
        </h1>
        <div class="row mx-5 mt-3 justify-content-evenly align-items-center text-center">
          <div class="col-xl-3 col-lg-4 col-sm-5 border rounded-4 px-4 cajaBeneficio mb-4">
            <p class="mt-3 mb-2 text-black fs-6 benefTitulo">OPTIMIZACIÓN DEL SERVICIO</p>
            <hr>
            <p class="detallesP">Reduce los tiempos de espera y mejora la eficiencia, permitiendo un servicio más rápido y preciso.</p>
          </div>
          <div class="col-xl-3 col-lg-4 col-sm-5 border rounded-4 px-4 cajaBeneficio mb-4">
            <p class="mt-3 mb-2 text-black fs-6 benefTitulo">MEJORA LA EXPERIENCIA DEL CLIENTE</p>
            <hr>
            <p class="detallesP">Una atención más rápida y sin errores crea una experiencia positiva, aumentando la probabilidad de que los clientes regresen.</p>
          </div>
        </div>
        <div class="row mx-5 justify-content-evenly align-items-center text-center">
          <div class="col-xl-3 col-lg-4 col-sm-5 border rounded-4 px-4 cajaBeneficio mb-4">
            <p class="mt-3 mb-2 text-black fs-6 benefTitulo">MAYOR FLUIDEZ</p>
            <hr>
            <p class="detallesP">Al agilizar los pedidos y la comunicación con la cocina, el flujo de trabajo se vuelve más fluido, lo que maximiza el rendimiento en momentos de alta demanda.</p>
          </div>
          <div class="col-xl-3 col-lg-4 col-sm-5 border rounded-4 px-4 cajaBeneficio mb-4">
            <p class="mt-3 mb-2 text-black fs-6 benefTitulo">POTENCIA AL PERSONAL</p>
            <hr>
            <p class="detallesP">Los camareros pueden centrarse en tareas de mayor valor, como la atención al cliente, mejorando tanto el ambiente de trabajo como la calidad del servicio.</p>
          </div>
        </div>
      </div>
    </div>
    
    <img src="../assets/fondo_landin_blur.jpg" class="position-fixed" style="z-index: -2;  top: 0; height: 100vh; width: 99.3vw; object-fit: cover;">

    <!--pantalla 6: pricing-->
    <div class="screen" id="pricing" style="background-color: transparent !important;">
      <div class="row mx-0 justify-content-evenly align-items-center text-center">
        <!--EWU BASICO-->
        <div class="col-xl-3 col-lg-4 col-sm-5 col-9 border rounded-5 cajaPricing p-4">
          <p class="mb-3 text-black fs-4 fw-bold" style="color: #f47700 !important;"><i>EWU Básico</i></p>
          <ul class="list-unstyled">
            <li class="row m-0 mb-2">
              <i class="fa-regular fa-circle-check fs-4 col-2 pt-1" style="color: #28a331;"></i>
              <p class="text-start text-black col-10">
                <span class="fw-bold">Perfil Cliente:</span>
                <br />
                Realización de pedidos desde la app.
              </p>
            </li>
            <li class="row m-0 mb-2">
              <i class="fa-regular fa-circle-check fs-4 col-2 pt-1" style="color: #28a331;"></i>
              <p class="text-start text-black col-10">
                <span class="fw-bold">Perfil Camareros:</span>
                <br />
                Gestión de comandas y mesas.
              </p>
            </li>
            <li class="row m-0 mb-2">
              <i class="fa-regular fa-circle-check fs-4 col-2 pt-1" style="color: #28a331;"></i>
              <p class="text-start text-black col-10">
                <span class="fw-bold">Perfil Admin:</span>
                <br />
                Gestión del restaurante y estadísticas.
              </p>
            </li>
            <li class="row m-0 mb-2">
              <i class="fa-regular fa-circle-check fs-4 col-2 pt-1" style="color: #28a331;"></i>
              <p class="text-start col-10" style="color: black;">
                <span class="fw-bold">Capacitación Especializada:</span>
                <br />
                Formación inicial para el personal sobre el uso de la plataforma.
              </p>
            </li>
            <li class="row m-0 mb-2">
              <i class="fa-regular fa-circle-check fs-4 col-2 pt-1" style="color: #28a331;"></i>
              <p class="text-start text-black col-10">
                <span class="fw-bold">Soporte técnico</span>
              </p>
            </li>
          </ul>
          <h2 class="text-center precioCaja">70€/mes</h2>
        </div>
        
        <!--EWU PRO-->
        <div class="col-xl-3 col-lg-4 col-sm-5 col-9 border rounded-5 cajaPricing p-4 ">
          <p class="mb-3 text-black fs-4 fw-bold" style="color: #f47700 !important;"><i>EWU HW</i></p>
            <ul class="list-unstyled mb-2 mt-2">
              <li class="row m-0">
                <i class="fa-regular fa-circle-check fs-4 col-2 pt-1" style="color: #28a331;"></i>
                <p class="text-start col-10" style="color: black;">
                  <span class="fw-bold">EWU Básico</span>
                </p>
              </li>
              <i class="fa-solid fa-plus fs-3 mb-2" style="color: #f47700 !important;"></i>
              <li class="row m-0 mb-2">
                <i class="fa-regular fa-circle-check fs-4 fs-4 col-2 pt-1" style="color: #28a331;"></i>
                <p class="text-start col-10" style="color: black;">
                  <span class="fw-bold">Dispositivos:</span>
                  <br />
                  Tablets integradas para los empleados.
                </p>
              </li>
            </ul>
            <h2 class="text-center mt-3 precioCaja">70€/mes</h2>
            <p class="text-center" style="color: #270a47; font-size: 1rem">1er mes + 150€ x Dispositivo</p>
        </div>
      </div>
    </div>

    <!--pantalla 7: FAQ'S-->
    <div class="py-5 screen faqs">
      <div class="accordion accordion-flush align-items-center justify-content-center px-sm-5 px-2 py-5 col-sm-10 col-11 mx-auto" id="accordionFlushExample" style="background-color: white;">
        <h2 class="fw-bold text-center mb-3 mx-auto" style="color: #2d1674 !important; background-color: white;">FAQ's</h2>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
              ¿Cómo puede mi restaurante beneficiarse de esta app?
            </button>
          </h2>
          <div id="flush-collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
            <div class="accordion-body">Nuestra app permite una gestión más eficiente del restaurante al automatizar el proceso de pedidos, mejorar la comunicación entre el personal y proporcionar análisis detallados de rendimiento. Esto se traduce en tiempos de espera más cortos, mejor servicio al cliente y una operación más rentable.</div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
              ¿Es difícil implementar la app en mi restaurante?
            </button>
          </h2>
          <div id="flush-collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
            <div class="accordion-body">No, la implementación es rápida y sencilla. Nuestro equipo te guiará durante todo el proceso de configuración, asegurando que tu personal se familiarice rápidamente con la plataforma y empiece a beneficiarse de sus funciones desde el primer día.</div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
              ¿Qué dispositivos son compatibles con la app?
            </button>
          </h2>
          <div id="flush-collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
            <div class="accordion-body">La app es compatible con la mayoría de los dispositivos, incluyendo smartphones, tablets, y sistemas de punto de venta (POS). Solo necesitas una conexión a internet para operar la plataforma sin problemas.</div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
              ¿Cómo se manejan los datos y la privacidad de mis clientes?
            </button>
          </h2>
          <div id="flush-collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
            <div class="accordion-body">Utilizamos protocolos de seguridad avanzados y cifrado de extremo a extremo para proteger toda la información, cumpliendo con las normativas vigentes sobre protección de datos.</div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
              ¿Puedo personalizar la app según las necesidades de mi restaurante?
            </button>
          </h2>
          <div id="flush-collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
            <div class="accordion-body">Sí, nuestra app es altamente personalizable. Puedes adaptar el menú, las promociones, y otras funcionalidades para que se alineen con la identidad y necesidades específicas de tu restaurante.</div>
          </div>
        </div>
        
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
              ¿Qué soporte se ofrece en caso de problemas o dudas?
            </button>
          </h2>
          <div id="flush-collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
            <div class="accordion-body">Ofrecemos soporte técnico telefónico, Email y WhatsApp para resolver cualquier duda o problema que pueda surgir.</div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
              ¿Hay costos adicionales o comisiones por usar la app?
            </button>
          </h2>
          <div id="flush-collapseSeven" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
            <div class="accordion-body">Nuestra app funciona con un modelo de suscripción transparente, sin comisiones ocultas. Te ofrecemos diferentes planes adaptados a las necesidades de tu negocio.</div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
              ¿Cómo puedo empezar a usar la app en mi restaurante?
            </button>
          </h2>
          <div id="flush-collapseEight" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
            <div class="accordion-body">Comenzar es muy fácil. Solo tienes que contactarnos a través del formulario de nuestra web o llamarnos directamente, y uno de nosotros te ayudará con el proceso de registro y configuración.</div>
          </div>
        </div>

      </div>
    </div>

    <!--pantalla 8: formulario-->
    <div class="screen formulario overflow-hidden" id="contacto" style="background-color: #f47700; width: 99.3vw;">
      <div class="col-11 bg-white p-5 m-0" style="min-height: 91vh !important; padding-left: 12vw !important;">
        <h1 class="fw-bold">Solicitar información</h1>
        <p class="col-md-7 text-justify mt-4" style="color: #555555;">Si necesita información sobre algo más concreto o preguntas más personalizadas, envíenos un formulario y le contestaremos lo antes posible.</p>
        
        <div class="col-md-6" style="color: #6a6666;">
            <div class="form-floating mb-3" style="border-bottom: 1px solid #bbbbbb">
              <input type="text" class="form-control border-0" id="floatingInput" v-model="nombre">
              <label for="floatingInput">Nombre</label>
            </div>
            <div class="form-floating mb-3" style="border-bottom: 1px solid #bbbbbb">
              <input type="text" class="form-control border-0" id="floatingInput" v-model="email">
              <label for="floatingInput">Email <span class="text-danger">*</span></label>
            </div>
            <div class="form-floating mb-3" style="border-bottom: 1px solid #bbbbbb">
              <input type="text" class="form-control border-0" id="floatingInput" v-model="telefono">
              <label for="floatingInput">Telefono <span class="text-danger">*</span></label>
            </div>
            <div class="form-floating mb-3" style="border-bottom: 1px solid #bbbbbb">
              <input type="text" class="form-control border-0" id="floatingInput" v-model="restaurante">
              <label for="floatingInput">Restaurante</label>
            </div>
            <div class="form-floating mb-2" style="border-bottom: 1px solid #bbbbbb">
              <textarea class="form-control border-0" v-model="mensaje" id="floatingTextarea2" style="height: 100px"></textarea>
              <label for="floatingTextarea2">Mensaje</label>
            </div>
            <span class="text-danger d-none" id="error-message">Debes rellenar los campos requeridos para enviar la solicitud</span>
            <span class="text-success d-none" id="correct-message">¡Contactaremos contigo ensegida!<br><img src="@/assets/tick.gif" class="img-fluid" style="width: 80px; height: auto;"/><br></span>
            <p class="btn btn-dark py-2 col-sm-5 mt-2 fw-semibold" @click="enviarFormulario">ENVIAR</p>
        </div>
      </div>
      <div id="cuboNegro" class="col align-self-center bg-dark p-5 d-none d-md-block" style="z-index: 100; height: 20rem; width: 25vw; color: white; position: relative; margin-top: -70vh; left: 75vw;">
        <h4 class="fw-bold">Contáctenos</h4>
        <hr style="width: 30%; border: 1.5px solid #514f4f;">
        <div class="d-flex">
          <i class="fa-brands fa-whatsapp me-3"></i>
          <p>654321987</p>
        </div>
        <div class="d-flex">
          <i class="fa-solid fa-phone fs-6 me-3"></i>
          <p>654321987</p>
        </div>
        <div class="d-flex">
          <i class="fa-solid fa-envelope me-3"></i>
          <p>654321987</p>
        </div>
        <div class="d-flex">
          <i class="fa-solid fa-globe me-3"></i>
          <p>654321987</p>
        </div>
      </div>
    </div>
    <div id="escondite-footer"></div>
    <!--footer-->
    <footer id="footer" class="position-fixed" style="z-index: -1; bottom: 0; height: auto; width: 99.3vw;"> <!--bottom: 0; z-index:-1-->
      <div class="row m-0 bg-dark ">
        <div class="col-lg-6 col-sm-8 col-12 px-md-5">
          <img src="../assets/EWU_Blanco.png" style="height: 7vh;" class="my-4 px-5">
          <p class="fs-6 text-justify px-5">Nos apasiona impulsar la innovación en la industria gastronómica, creando soluciones que transforman la manera en que los restaurantes operan y se conectan con sus clientes. Nos comprometemos a ofrecer herramientas que mejoren la eficiencia y la experiencia en cada mesa, asegurando que cada interacción sea fluida y memorable.</p>
        </div>
        <div class="col-lg-6 col-sm-4 col-12 text-white py-4 d-flex justify-content-sm-center ">
          <div class="px-sm-0 px-5">
            <h5>CONTÁCTENOS</h5>
            <hr style="width: 30%; border: 1.5px solid #514f4f;">
            <div class="d-flex">
              <i class="fa-brands fa-whatsapp me-3"></i>
              <p>654321987</p>
            </div>
            <div class="d-flex">
              <i class="fa-solid fa-phone fs-6 me-3"></i>
              <p>654321987</p>
            </div>
            <div class="d-flex">
              <i class="fa-solid fa-envelope me-3"></i>
              <p>654321987</p>
            </div>
            <div class="d-flex">
              <i class="fa-solid fa-globe me-3"></i>
              <p>654321987</p>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-black px-5">
        <p class="my-3 text-end"><span class="me-2">&copy;</span> 2024 Eat With Us</p>
      </div>
    </footer>
    <SpinnerComponent id="spinner" class="d-none"></SpinnerComponent>
  </div>
</template>

<script>
import axios from 'axios';
import SpinnerComponent from '../components/SpinnerComponent.vue'

export default {
  name: "LandingPage",
  data: function () {
    return {
      showButton: false,
      image_screen5: require('@/assets/screen5.jpg'),
      nombre: "",
      email: "",
      telefono: "",
      restaurante: "",
      mensaje: ""
    };
  },
  components: {
    SpinnerComponent
  },
  computed: {
    divDatos() {
      return {
        backgroundImage: `url(${this.image_screen5})`,
        width: '100%',
        padding: '50px 0px 50px 0px',
        'background-size': 'cover',
        'background-position': 'center',
        'background-repeat': 'no-repeat'
      };
    },
  },
  mounted() {
    document.getElementsByClassName("page")[0].addEventListener("scroll", this.handleScroll);

    const footer = document.getElementById("footer");
    const escondite = document.getElementById("escondite-footer");

    // Crea un ResizeObserver para observar cambios en el tamaño del footer
    const resizeObserver = new ResizeObserver(() => {
      const scrollPosition = window.scrollY || window.pageYOffset;
      const windowHeight = window.innerHeight; // Altura visible del viewport
      const documentHeight = document.documentElement.scrollHeight;
      const distanceToBottom = documentHeight - (scrollPosition + windowHeight);

      escondite.style.height = footer.offsetHeight + distanceToBottom + "px";
      console.log("observador")
    });

    // Empieza a observar el footer
    resizeObserver.observe(footer);
  },
  beforeUnmount() {
    // Eliminar el evento scroll para evitar fugas de memoria
    document.getElementsByClassName("page")[0].removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    scrollToTop() {
      document.getElementsByClassName("page")[0].scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    handleScroll() {
      const scrollPosition = document.getElementsByClassName("page")[0].scrollTop;
      const footer = document.getElementById("footer");
      const contacto = document.getElementById("contacto");
      //const escondite = document.getElementById("escondite-footer")
      //escondite.style.height = footer.offsetHeight + "px"
      console.log(footer.offsetHeight)
      //console.log(contacto.getBoundingClientRect())
      if (contacto.getBoundingClientRect().height + 100 > (contacto.getBoundingClientRect().y + contacto.getBoundingClientRect().height)){
        footer.style.display="block"
      }
      else{
        footer.style.display="none"
      }
      //console.log(footer)
      //console.log(scrollPosition)
      // Mostrar el botón cuando el usuario ha bajado al menos un 30% de la página
      this.showButton = scrollPosition > document.documentElement.scrollHeight * 0.3;
    },
    async enviarFormulario(){
      if (!this.email || !this.telefono){
        document.getElementById("error-message").classList.remove("d-none")
        return
      }
      document.getElementById("error-message").classList.add("d-none")
      document.getElementById("spinner").classList.remove("d-none")
      let formulario = {
        nombre : this.nombre,
        email: this.email,
        telefono: this.telefono,
        restaurante: this.restaurante,
        mensaje: this.mensaje
      }
      const url = 'http://127.0.0.1:8000/v1/api/app/FormularioLandin';
      try {
          const response = await axios.post(url, formulario, {
          headers: {}
          });
          console.log(response.data);
          document.getElementById("correct-message").classList.remove("d-none")
          document.getElementById("spinner").classList.add("d-none")
          this.nombre = ""
          this.email = ""
          this.telefono = ""
          this.restaurante = ""
          this.mensaje = ""
          setTimeout(() => {
              document.getElementById("correct-message").classList.add("d-none")
          }, 5000);
      } catch (error) {
          console.error('Error fetching shopping cart:', error);
          document.getElementById("error-message").classList.remove("d-none")
          document.getElementById("spinner").classList.add("d-none")
      }
    }
},
};
</script>

<style scoped>

.page::-webkit-scrollbar {
  width: 0.7vw; /* Fija el ancho de la barra de scroll en un valor de viewport width */
}

.page::-webkit-scrollbar-thumb {
    background-color: #290d53;
    border-radius: 2px;
  }

* {
  box-sizing: border-box;
  overflow: hidden;
}

body {
  background-image: url(../assets/login-image.jpg);
  overflow: hidden !important;
}

.screen {
  width: 100vw;
  min-height: 91vh;
  overflow-y: auto;
  background-color: white;
  margin: 0;
}

.subir {
  padding: 8px 11px 8px 11px;
  background-color: #f47700; 
  border-radius: 50%; 
  position: fixed; 
  bottom: 2.5rem; 
  right: 3rem; 
  z-index: 1000;
}

.v-field--variant-filled .v-field__overlay {
  opacity: 0.01 !important;
}

#inicio {
  background-color: #290d53;
  color: white;
  margin-top: 9vh;
}

.cajas {
  min-height: 50vh;
  background-color: white;
  padding: 5vh;
  color: #dddddd;
  border: solid #b8b3b350 1px;
}

.cajas:hover {
  background-color: #f47700;
  transition: 1s;
  color: #270a47;
}

.carousel-indicators [data-bs-target] {
  width: 2vh; /* Inicialmente circular */
  height: 2.3vh;
  border-radius: 50%;
  background-color: #f476008f !important; /* Color en estado normal */
  transition: width 0.4s ease;
  box-sizing:inherit;
  border: 1px solid transparent
}

.carousel-indicators .active {
  width: 4vh; /* Se alarga cuando está activa */
  background-color: #f47700 !important; /* Color cuando está activa */
  border-radius: 15px; /* Hace que el elemento sea ovalado */
}

/*screen 4*/
.detallesP {
  color: #555555;
}

/*screen 5*/
.image-container {
  position: relative;
  width: 100vw; /* Ocupa todo el ancho de la pantalla */
  min-height: 10vh; /* Ajusta según tus necesidades */
}

.blurred-img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Para evitar distorsiones y mantener las proporciones */
  filter: blur(3px);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center; /* Centra verticalmente */
  padding: 1rem; /* Añade padding a los lados para espacio */
  
}

.text-container {
  display: flex;
  justify-content: space-evenly !important;
  width: 100%;
}

.cajaDato {
  background: rgba(0, 0, 0, 0.182); /* Fondo oscuro semitransparente */
  border-radius: 10px;
  min-height: 18vh;
  padding: 20px 20px 20px 20px;
}

.cajaDato h1, p {
  color: white;
}

.cajaDato h1 {
  font-size: 50px;
}

.cajaDato p {
  font-size: 14px;
}

.cajaBeneficio hr {
  width: 70%; 
  border: 1px solid #f47700; 
  margin: auto;
  margin-bottom: 2vh;
}

.cajaBeneficio {
  min-height: 25vh; 
  align-content: center;
  border-color: #fcb36e !important;
}

.cajaPricing{
  min-height: 50vh;
  background-color: white;
  margin-top: 5rem;
}

.accordion-button:not(.collapsed) {
  font-weight: bold; /* Cambia la fuente a negrita */
}

.carousel-item{
  height: 90vh !important;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  filter: invert(40%) sepia(100%) saturate(200%) hue-rotate(270deg) brightness(80%) contrast(120%);
}

.faqs {
  background-color: transparent !important; 
  font-size: 1rem !important;
}

.precioCaja {
    font-size: 2rem !important;
  }

@media (max-width: 1380px) {
  
  #inicio h1 {
    font-size: 4rem !important;
  }
  #inicio h2 {
    font-size: 2rem !important;
  }
}


@media (max-width: 1200px) {
  #inicio h1 {
    font-size: 3.5rem !important;
  }

  #inicio h2 {
    font-size: 1.8rem !important;
  }
  

  .cajas {
    padding: 3vh;
    min-height: 42vh;
  }

  .cajas h1{
    font-size: 30px !important;
  }

  .cajas h3{
    font-size: 20px !important;
  }

  .cajaBeneficio {
    padding: 1vh;
    min-height: 20vh;
    font-size: 16px;
  }

  .benefTitulo {
    font-size: 17px !important;
  }

}

@media (max-width: 992px) {
  
  #inicio h1 {
    font-size: 50px !important;
    padding: 1vh !important;
  }

  #inicio h2 {
    font-size: 22px !important;
  }


  .cajas {
    padding: 3vh;
    min-height: 40vh;
    width: 28vh;
    margin: 1vh;
  }
  .cajas h1{
    font-size: 25px !important;
  }

  .cajas h3{
    font-size: 20px !important;
  }

  .cajaBeneficio {
    padding: 1vh;
    min-height: 21vh;
    font-size: 15px;
  }

  .benefTitulo {
    font-size: 16px !important;
  }

  .cajaPricing {
    padding: 1vh;
    font-size: 13px !important;
  }

  .cajaDato {
    height: 20vh !important;
  }

  .cajaDato h1 {
  font-size: 40px;
  }

  .cajaDato p {
  font-size: 12px;
  }

  .carousel-item {
    font-size: 14px;
  }

  .utilidades_pc_img{
    width: 14vw !important;
  }

  .utilidades_movil_img{
    width: 7vw !important;
  }
  
  #cuboNegro{
    width: 35vw !important;
    left: 65vw !important;
  }


}

@media (max-width: 768px) {
  
  #inicio h1 {
    font-size: 40px !important;
  }


  .cajas {
    padding: 5vh;
    min-height: 40vh;
    width: 50vh;
    margin: 2vh;
  }
  .cajas h1{
    font-size: 30px !important;
  }

  .cajas h3{
    font-size: 25px !important;
  }

  .cajaBeneficio {
    padding: 1vh;
    min-height: 27vh;
    font-size: 15px;
  }

  .benefTitulo {
    font-size: 16px !important;
  }


  .cajaPricing {
    padding: 1vh;
    font-size: 13px !important;
  }


  .cajaDato {
  min-height: 20vh !important;
  }

  .cajaDato h1 {
  font-size: 31px;
  }

  .cajaDato p {
  font-size: 11px;
  }

  .carousel-item {
    padding: 10vh;
  }

  .carousel-item img {
    width: 40vw !important;
  }
  footer img{
    height: 6vh !important;
  }

  .utilidades_pc_img{
    width: 27vw !important;
  }

  .utilidades_movil_img{
    width: 11vw !important;
  }

}

@media (max-width: 576px) {
  
  #inicio h1 {
    font-size: 45px !important;
    width: 50vh;
  }

  #utilidades h1 {
    font-size: 25px;
  }


  .cajas {
    padding: 3vh;
    min-height: 40vh;
    width: 35vh;
    margin: 2vh;
  }

  .cajaBeneficio {
    padding: 1vh;
    min-height: 22vh;
  }

  .cajaPricing {
    padding: 1vh;
    font-size: 13px !important;
  }

  .cajaDato {
    min-height: 5vh !important;
  }

  .cajaDato h1 {
  font-size: 40px;
  }

  .cajaDato p {
  font-size: 12px;
  }

  .carousel-item img {
    width: 60vw !important;
  }

  footer p, footer i{
    font-size: 0.9rem !important;
    padding: 0px 20px 0px 20px !important;
  }

  .carousel-item {
    padding: 7vh;
  }

  .utilidades_pc_img{
    width: 25vw !important;
  }

  .utilidades_movil_img{
    width: 14vw !important;
  }

  header .nav-item{
    text-align: center;
    font-size: 1.1rem;
  }

  header .nav-link{
    color: #290d53 !important;
    /*border-bottom: 0.1rem solid rgb(118, 118, 118);*/
  }

  header img{
    padding-left: 5vw !important;
  }

  .navbar-nav-scroll {
    max-height: 100vh;
    padding-right: 0px !important;
  }
}

@media (max-width: 310px) {
  
  #inicio h1 {
    font-size: 40px !important;
    width: 50vh;
  }

  .cajas {
    padding: 2vh;
    min-height: 35vh;
    width: 30vh;
    margin: 2vh;
  }
  
  .cajaBeneficio {
    min-height: 23vh;
    font-size: 13px;
  }

  .benefTitulo {
    font-size: 14px !important;
  }

  .cajaBeneficio hr {
  margin-bottom: 1vh;
  }

  

  .precioCaja {
    font-size: 1.7rem !important;
  }

  footer img{
    height: 4vh !important;
  }
  footer p, footer i{
    font-size: 0.9rem !important;
    padding: 0px 10px 0px 10px !important;
  }

  .carousel-item {
    padding: 4vh;
  }

  .utilidades_pc_img{
    width: 35vw !important;
  }

  .utilidades_movil_img{
    width: 21vw !important;
  }
}
</style>