<template>
  <div>
    <div v-if="visible" class="modal fade show d-block z-3" tabindex="-1" style="background: rgba(139, 139, 139, 0.5);" aria-modal="true" role="dialog">
      <div @click="$emit('close')" class="h-100 w-100 position-fixed z-1">
      </div>
      <div class="modal-dialog modal-dialog-centered d-flex justify-content-center align-items-center pb-4 z-2" >
        <div class="modal-content rounded-4 border-0 pb-3" style="width: 90%; max-height: 70vh;">
          <div class="modal-header flex-column align-items-center position-relative pb-2">
            <h3 class="modal-title fw-bold">Pedido {{ order.number }}</h3>
            <span>{{ formatTime(order.date) }}</span>
            <div>
              <span v-if="order.state === 'PENDIENTE'" class="badge bg-danger">Pendiente</span>
              <span v-else-if="order.state === 'EN CURSO'" class="badge bg-warning">En curso</span>
              <span v-else-if="order.state === 'ENTREGADO'" class="badge bg-success">Entregado</span>
              <span v-else-if="order.state === 'CANCELADO'" class="badge bg-grey">Cancelado</span>
              <span v-else-if="order.state === 'PAGADO'" class="badge bg-primary">Pagado</span>
            </div>
            <button type="button" class="position-absolute top-0 end-0 my-2 mx-3" style="color: #F47700;" aria-label="Close" @click="$emit('close')"><i class="fa-solid fa-xmark" style="font-size:25px"></i></button>
          </div>
          <div class="modal-body" style="overflow-y: auto;">
            <div>
              <div v-for="plate in order.plates" :key="plate.id" class="card border-none shadow mb-3 rounded-4">
                <div class="row g-0 h-100">
                  <div class="col-4 d-flex align-items-center justify-content-center p-0">
                    <img :src="plate.photo ? plate.photo : noPhoto" :alt="plate.name" class="img-fluid rounded-left" id="plate-img" />
                  </div>
                  <div class="col-8 d-flex flex-column p-0">
                    <h5 class="card-title m-2 pl-2">{{ plate.name }}</h5>
                    <div class="card-body p-2" style="overflow-y: auto;">
                      <p class="px-2" style="color: #F47700">
                        {{ plate.description }}
                      </p>
                    </div>
                    <div class="card-footer text-right">
                      <div class="row">
                        <div class="col-5 d-flex align-items-center justify-content-center">
                          <span v-if="plate.state === 'PENDIENTE'" class="badge bg-danger">Pendiente</span>
                          <span v-else-if="plate.state === 'EN CURSO'" class="badge bg-warning">En curso</span>
                          <span v-else-if="plate.state === 'ENTREGADO'" class="badge bg-success">Entregado</span>
                          <span v-else-if="plate.state === 'CANCELADO'" class="badge bg-grey">Cancelado</span>
                          <span v-else-if="plate.state === 'PAGADO'" class="badge bg-primary">Pagado</span>
                        </div>
                        <div class="col-7">
                          <span style="font-size: 12px;" class="fw-semibold">{{ plate.price.toFixed(2) }}€ x {{ plate.amount }} </span> <strong> {{ (plate.price * plate.amount).toFixed(2) }}€</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p class="fw-bold fs-4 mt-4 text-end">Total: {{ order.price }}€</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import noPhoto from './../assets/no_plate.png';
  export default {
    props: {
      visible: {
        type: Boolean,
        required: true
      },
      order: {
        type: Object,
        required: true
      }
    },
    data() {
    return {
      noPhoto
    };
  },
    methods: {
      formatTime(dateTime) {
          const date = new Date(dateTime);
          console.log(date)
          const hours = String(date.getHours()).padStart(2, '0'); 
          let minutes = String(date.getMinutes()).padStart(2, '0');
          return `${hours}:${minutes}`;
      },
    }
  };
</script>

<style>
#plate-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  background-color: #f0f0f0;
}

.rounded-left {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.card-body {
  max-height: 80px;
  overflow-y: auto;
}

.card-body::-webkit-scrollbar, .modal-body::-webkit-scrollbar{
  width: 4px;
}

.card-body::-webkit-scrollbar-thumb, .modal-body::-webkit-scrollbar-thumb {
  background-color: #f47600ad;
  border-radius: 10px;
}

.card-footer {
  background-color: #f8f9fa;
}
</style>