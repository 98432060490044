<template>
  <div class="mt-4 pb-5">
    <h1 class="fw-normal my-3 text-center">Datos del restaurante</h1>
    <form class="bg-white p-5 rounded-5">
      <div class="form-group mb-3">
        <label for="restaurantName">Nombre del restaurante: <span class="text-danger">*</span></label>
        <input type="text" class="form-control w-75 shadow ml-2 my-2" id="restaurantName"/>
      </div>
      <div class="form-group mb-4 w-50">
        <label class="my-3">Logotipo:</label>
        <div class="d-flex justify-content-center align-items-center border border-secondary p-3" style="height: 150px;">
          <input type="file" class="form-control-file" @change="onFileSelected" style="display: none;" ref="fileInput"/>
          <button class="btn btn-outline-primary" @click="triggerFileInput">Upload file</button>
        </div>
      </div>
      <div class="form-group mb-3">
        <label for="restaurantLocation">Ubicación del restaurante:</label>
        <input type="text" class="form-control w-75 shadow ml-2 mt-3" id="restaurantLocation" placeholder="Ej: Calle Santa Fe, 2, Alicante, 03002"/>
        <div class="form-check mt-2 ml-2">
          <input class="form-check-input" type="checkbox" id="locationCheck"/>
          <label class="form-check-label" for="locationCheck">
            Comprobar la ubicación del comensal para verificar si está en el restaurante
          </label>
        </div>
      </div>
      <h3 class="mt-5 mb-3">Configuración del restaurante</h3>
      <div class="form-group mb-4">
        <label for="tableTime" class="form-label">
          Tiempo activo de la mesa (minutos): <span class="text-danger">*</span>
        </label>
        <div class="d-flex align-items-center">
          <input type="number" v-model="tableTime" class="form-control text-center" id="tableTime" min="0" style="width: 80px;" />
          <button class="btn btn-outline-secondary mx-2" @click.prevent="decreaseTableTime">-</button>
          <button class="btn btn-outline-secondary" @click.prevent="increaseTableTime">+</button>
        </div>
      </div>
      <div class="form-group">
        <label for="horarioCocina" class="form-label">
          Horario de cocina: <span class="text-danger">*</span>
        </label>
        <div v-for="(schedule, index) in schedules" :key="index" class="d-flex align-items-center mb-2 ml-2">
          <span>de</span>
          <input type="time" v-model="schedule.inicio" class="form-control mx-2 text-center" style="width: 80px;"/>
          <span>a</span>
          <input type="time" v-model="schedule.fin" class="form-control mx-2 text-center" style="width: 80px;"/>
          <button v-if="schedules.length > 1 && index > 0" class="btn" @click.prevent="removSechedule(index)">
            <i class="bi bi-x"></i>
          </button>
          <button v-if="schedules.length === 1" class="btn" style="color:#ff7a00" @click.prevent="addSchedule">
            <img src="../assets/icons8-más-50.png" class="w-50"/>
          </button>
        </div>
      </div>
      <div class="form-group mb-3 d-flex flex-wrap flex-md-nowrap">
        <div class="w-100 w-md-50">
          <label for="tpv" class="my-2">TPV:</label>
          <select id="tpv" class="form-select shadow ml-2">
            <option value="value">Value</option>
          </select>
        </div>
        <div class="w-100 w-md-50 mt-3 mt-md-0">
          <label for="tpvKey" class="my-2">Clave TPV:</label>
          <input type="text" class="form-control shadow ml-2" id="tpvKey" placeholder="ABCD-1234-EFGH-5678"/>
        </div>
      </div>
      <div class="form-group mb-3">
        <label class="d-block">Forma de pago: <span class="text-danger">*</span></label>
        <div class="form-check">
          <input class="form-check-input custom-radio" type="radio" name="payOption" id="pagoCadaPedido" value="pagoCadaPedido"/>
          <label class="form-check-label" for="pagoCadaPedido">
            Pago en cada pedido
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input custom-radio" type="radio" name="payOption" id="pagoAlFinal" value="pagoAlFinal" checked/>
          <label class="form-check-label" for="pagoAlFinal">
            Pago al final
          </label>
        </div>
      </div>
      <div class="form-group mb-3">
        <div class="d-flex align-items-center">
          <label class="d-block me-2">Modo Avanzado</label>
          <div class="form-check form-switch">
            <input class="form-check-input custom-slider" style="width:50px" type="checkbox" id="advanceMode" v-model="advanceMode"/>
            <label class="form-check-label" for="advanceMode"></label>
          </div>
        </div>
        <small class="text-muted">
          El modo Avanzado de Eat with us te permite crear modos de uso de la plataforma adaptados a tu restaurante.
        </small>
      </div>
      <div v-if="advanceMode" class="restaurant-list">
        <div class="d-flex overflow-auto custom-scroll">
          <div v-for="restaurant in restaurants" :key="restaurant.id" class="card text-center p-3 mx-2 mb-2 rounded-5 shadow" style="min-width: 150px; min-height: 130px; border: 1px solid #eee; background-color: #f7f7f7; overflow: hidden;">
            <div class="d-flex flex-column flex-md-row g-0" style="height: 100%; width: 100%;">
              <div class="col d-flex flex-column justify-content-center p-2 mt-2">
                <div class="p-0">
                  <h3 class="category-name"
                      style="font-size: 1.2rem; text-align: center; max-width: 100%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                    {{ restaurant.name }}
                  </h3>
                  <p class="category-description"
                    style="font-size: 1rem; text-align: center; max-width: 100%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                    {{ restaurant.city }}
                  </p>
                  <div class="card-actions mt-2 d-flex align-items-center justify-content-center">
                    <i class="fa-solid fa-pencil mr-2 action-icon" style="color: #f47700;"></i>
                    <i class="fa-regular fa-trash-can action-icon" style="color: #f47700;"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between mt-3">
        <button type="button" @click="openModalNewMode" class="btn btn-outline-secondary">Crear Modo</button>
      </div>
      <div class="d-flex align-items-center justify-content-center">
        <button class="btn btn-secondary">Guardar</button>
      </div>
    </form>
    <NewModeModalComponent
      :isOpen="isModalOpen" 
      @close="closeModal" 
      @guardar="guardarNuevoModo"
    />
  </div>
</template>

<script>
import NewModeModalComponent from '@/components/NewModeModalComponent.vue';

  export default {
    components:{
      NewModeModalComponent
    },
    methods: {
      increaseTableTime() {
        this.tableTime += 1;
      },
      decreaseTableTime() {
        if (this.tableTime > 0) {
          this.tableTime -= 1;
        }
      },
      addSchedule() {
        this.schedules.push({ inicio: "00:00", fin: "00:00" });
      },
      removSechedule(index) {
        if (this.schedules.length > 1) {
          this.schedules.splice(index, 1);
        }
      },
      triggerFileInput() {
        this.$refs.fileInput.click();
      },
      onFileSelected(event) {
        const file = event.target.files[0];
        console.log('Archivo seleccionado:', file);
      },
      openModalNewMode() {
        this.isModalOpen = true;
      },
      closeModal() {
        this.isModalOpen = false;
      },
      saveMewMode(mode) {
        console.log('Nuevo modo guardado:', mode);
      }
    },
    data() {
      return {
        tableTime: 120,
        schedules: [
          {idRestaurante: 123, inicio: "00:00", fin: "00:00" }
        ],
        advanceMode: false,
        isModalOpen: false,
        restaurants: [
          { id: 1, name: "Buffet 1", localitation: 'calle blabla 5 01002', city: 'Alicante' },
          { id: 2, name: "Buffet 2", localitation: 'calle blabla 5 01002', city: 'Alicante' },
          { id: 3, name: "Buffet 3", localitation: 'calle blabla 5 01002', city: 'Alicante' },
          { id: 4, name: "Buffet 4", localitation: 'calle blabla 5 01002', city: 'Alicante' },
          { id: 5, name: "Buffet 5", localitation: 'calle blabla 5 01002', city: 'Alicante' },
          { id: 6, name: "Buffet 6", localitation: 'calle blabla 5 01002', city: 'Alicante' },
          { id: 7, name: "Buffet 7", localitation: 'calle blabla 5 01002', city: 'Alicante' },
          { id: 8, name: "Buffet 8", localitation: 'calle blabla 5 01002', city: 'Alicante' },
          { id: 9, name: "Buffet 9", localitation: 'calle blabla 5 01002', city: 'Alicante' },
          { id: 10, name: "Buffet 10", localitation: 'calle blabla 5 01002', city: 'Alicante' },
          { id: 11, name: "Buffet 11", localitation: 'calle blabla 5 01002', city: 'Alicante' },
          { id: 12, name: "Buffet 12", localitation: 'calle blabla 5 01002', city: 'Alicante' },
          { id: 13, name: "Buffet 13", localitation: 'calle blabla 5 01002', city: 'Alicante' }
        ]
      }
    }
  }
</script>

<style scoped>
  .container {
    max-width: 800px;
    margin: auto;
  }

  .custom-slider {
    border-radius: 34px;
    width: 50px;
    height: 26px;
    position: relative;
  }

  .custom-slider:checked {
    background-color: #ff7a00;
  }

  .custom-radio:checked {
    background-color: #ff7a00;
  }

  .restaurant-list {
    white-space: nowrap;
    overflow-x: auto;
  }
  .restaurant-list .card {
    display: inline-block;
  }

  .custom-scroll::-webkit-scrollbar {
    height: 2px;
  }

  .custom-scroll::-webkit-scrollbar-thumb {
    background-color: #f47600ad;
    border-radius: 10px;
  }

  @media (max-width: 768px) {
    .container {
      padding: 0 15px;
    }
  }
</style>