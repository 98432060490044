<template>
    <div id="plate-expanded" class="d-none">
      <div @click="Close()" class="h-100 w-100 position-fixed z-1">
      </div>
      <div class="bg-white expanded rounded-4 p-3 z-2">
        <img :src="plate.photo ? plate.photo : noPhoto" class="card-img-top rounded-4 mb-2" alt="...">
        <h5 class="text-center mb-3 px-1">{{ plate.name }}</h5>
        <p class="description mb-1">{{ plate.description }}</p>
      </div>
    </div>
  </template>

<script>
import noPhoto from './../assets/no_plate.png';

export default {
  name: 'PlateExpanded',
  data: function() {
      return {
        plate:{},
        noPhoto
      }
    },
  components: {
    
  },
  computed:{
      
  },
  methods: {
    Close(){
        document.getElementById("plate-expanded").classList.add("d-none")
        this.plate = {}
    },
    ChangePlate(plate){
      this.plate = plate
      if (this.plate.amount == 0){
        this.plate.amount++
      }
      document.getElementById("plate-expanded").classList.remove("d-none")
    },
    ChangeAmount(change){
      this.plate.amount += change
      if(this.plate.amount <= 1){
        this.plate.amount = 1
      }
    }
  }
}

</script>
  
  <style scoped>
  /* Estilo para el contenedor del spinner */
  #plate-expanded {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(139, 139, 139, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100 !important;
    transition: all 3s ease
  }

  #plate-expanded img {
    height: 200px;
    object-fit: cover;
  }

  .expanded{
    width: 80%;
  }
  .description{
    padding: 0px 10px 0px 10px;
    max-height:150px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: scroll;
    text-overflow: ellipsis;
  }

  .description::-webkit-scrollbar{
    width: 4px;
  }

  .description::-webkit-scrollbar-thumb {
      background-color: #f47600ad;
      border-radius: 10px;
  }

  .botonCantidad{
    border: 2px solid #f47600a7;
    border-radius: 10px;
    color: #F47700;
  }

  .disabled{
    color: #b4b4b4a7;
    border: 2px solid #b4b4b4a7;
  }
  </style>
  