<template>
  <div v-if="showModal" class="modal fade show d-block z-3" tabindex="-1">
    <div @click="closeModal()" class="h-100 w-100 position-fixed z-1"></div>
    <div class="modal-dialog mt-4 d-flex align-items-center justify-content-center z-2">
      <div class="modal-content" style="width:90%">
        <div class="d-flex justify-content-center align-items-center mt-3 mb-3">
          <h3 class="modal-title">Editar Mesa "{{ localTable.number }}"</h3>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitEdit">
            <div class="row">
              <span id="span-error" class="text-danger text-center pb-3"></span>
              <div class="col-md-6 mb-5">
                <div class="form-group">
                  <b for="table-name">Nombre de la Mesa:</b><br />
                  <input type="text" id="name" v-model="localTable.number" class="form-control shadow rounded-3" />
                </div>
              </div>
              <div class="col-md-6 mb-5">
                <div class="form-group">
                  <b for="table-dinners">Nº de Comensales:</b><br />
                  <input type="number" id="dinners" v-model.number="localTable.max_diners" class="form-control shadow rounded-3" />
                </div>
              </div>
              <div class="col-md-12 mb-5">
                <div class="row">
                  <img v-if="imagePreview" :src="imagePreview" alt="Imagen de la mesa" class="img-fluid col-7 mx-auto" />
                </div>
              </div>
            </div>
            <div class="modal-footer align-items-center justify-content-center border-none">
              <button type="submit" class="btn btn-add text-white px-4 rounded-3" style="background-color: #f47700">Actualizar</button>
              <button type="button" @click="closeModal" class="btn btn-danger px-4 rounded-3">Cancelar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import defaultImage from '@/assets/imagenMesaPredefinida.jpg';

  export default {
    props: {
      showModal: Boolean,
      table: {
        type: Object,
        default: () => ({})
      },
    },
    data() {
      return {
        localTable: { ...this.table },
        imagePreview: this.table?.image || defaultImage,
      };
    },
    methods: {
      closeModal() {
        this.$emit('close-modal');
      },
      submitEdit() {
        if (!this.localTable.number || !this.localTable.max_diners) {
          document.getElementById("span-error").textContent = "Debes rellenar todos los campos para continuar"
          return;
        }
        this.$emit('update-table', this.localTable);
      },
    },
    watch: {
      table(newTable) {
        this.localTable = { ...newTable };
        this.imagePreview = newTable?.image || defaultImage;
      },
    },
  };
</script>

<style scoped>
  .modal {
    background: rgba(139, 139, 139, 0.5);
  }

  .modal-dialog {
    min-height: 90vh;
  }
</style>
