<template>
  <div class="container-fluid">
    <h1 class="text-center mt-4 fw-normal mb-3">Mis pedidos</h1>
    <div class="px-4" id="order-info">
      <span class="fw-semibold" v-if="orders.length==1"> {{ orders.length }} pedido</span>
      <span class="fw-semibold" v-else> {{ orders.length }} pedidos</span>
      <span id="total-price">Total: {{ totalPrice.toFixed(2) }}€</span>
    </div>
    <div id="orders" class="row justify-content-center mt-4 mb-5" style="overflow-y: auto;" v-if="orders.length > 0">
      <div class="col-11 shadow rounded-4 py-2 mb-3" v-for="order in orders" :key="order.id" @click="selectOrder(order)">
        <div class="align-items-center">
          <div id="platos" class="row">
            <div class="col-12">
              <img v-for="plate in order.plates" :key="plate.id" :src="plate.photo ? plate.photo : noPhoto" class="m-2 rounded-3" :style="{ width: '50px', height: '50px' }" :alt="plate.name" />
            </div>
          </div>
          <div class="row p-1 w-100 m-0 text-center">
            <div class="col-2 d-flex flex-column align-items-center justify-content-center">
              <b class="fs-2" style="color:#0A2533">{{ order.number }}</b>
              <span>{{ formatTime(order.date) }}h</span>
            </div>
            <div class="col-7 d-flex align-items-end justify-content-center">
              <b v-if="order.state === 'PENDIENTE'" class="text-danger">Pendiente</b>
              <b v-else-if="order.state === 'EN CURSO'" class="text-warning">En curso</b>
              <b v-else-if="order.state === 'ENTREGADO'" class="text-success">Entregado</b>
              <b v-else-if="order.state === 'CANCELADO'" class="text-grey">Cancelado</b>
              <b v-else-if="order.state === 'PAGADO'" class="text-primary">Pagado</b>
            </div>
            <div class="col-3 d-flex align-items-end justify-content-center fw-bold">
              <b>{{ order.price.toFixed(2) }}€</b>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="h-75 d-flex align-items-center justify-content-center">
      <div class="row">
        <img class="mx-auto col-xl-4 col-lg-5 col-md-7 col-sm-9 col-11" src="../../src/assets/anyone_order.png"/>
        <h2 class="text-center mt-3">Aun no has hecho ningun pedido</h2>
      </div>
    </div>
    <MyOrderDetails
      :visible="modalDisplay"
      :order="order"
      @close="modalDisplay = false"
    ></MyOrderDetails>
    <SpinnerComponent id="spinner"></SpinnerComponent>
  </div>
</template>

<script>
  import MyOrderDetails from '@/components/MyOrderDetails.vue'
  import axios from 'axios';
  import { mapState } from 'vuex';
  import SpinnerComponent from '../components/SpinnerComponent.vue'
  import noPhoto from './../assets/no_plate.png';

  export default {
    data() {
      return {
        orders: [],
        modalDisplay: false,
        order: null,
        first_charge: true,
        noPhoto
      };
    },
    components: {
      MyOrderDetails,
      SpinnerComponent
    },
    computed: {
      ...mapState(['token']),
      totalPrice() {
        let total = 0
        this.orders.forEach(order=>{
          total += order.price
        })
        console.log(total)
        return total
      },
    },
    methods: {
      formatTime(dateTime) {
          const date = new Date(dateTime);
          console.log(date)
          const hours = String(date.getHours()).padStart(2, '0'); 
          let minutes = String(date.getMinutes()).padStart(2, '0');
          return `${hours}:${minutes}`;
      },
      selectOrder(order) {
        this.order = order;
        this.modalDisplay = true;
      },
      async fetchGetOrders() {
        const token = this.token; // Suponiendo que tienes el token almacenado en Vuex
        const url = 'http://127.0.0.1:8000/v1/api/app/MyOrders';
        try {
          const response = await axios.get(url, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });

          // Maneja la respuesta
          console.log(response.data);
          this.orders = response.data
          if(this.first_charge){
            document.getElementById("spinner").classList.add("d-none")
            this.first_charge=false
          }
        } catch (error) {
          // Maneja el error
          this.orders = []
          console.error('Error fetching my orders:', error);
          document.getElementById("spinner").classList.add("d-none")
        }
      },
      startPolling() {
        this.fetchGetOrders();
        this.pollingInterval = setInterval(this.fetchGetOrders, 5000); // Cada 5 segundos
      },
      stopPolling() {
        clearInterval(this.pollingInterval);
      },
    },
    created() {
    this.startPolling();
    },
    beforeUnmount() {
      this.stopPolling();
    }
  };
</script>

<style>

  #platos {
    display: flex;
    overflow-x: auto; /* Cambiado de scroll a auto para mejor comportamiento */
    white-space: nowrap;
    position: relative;
    mask-image: linear-gradient(to right, transparent, black 0%, black 80%, transparent);
  }

  #platos img {
    flex-shrink: 0;
    height: 70px !important;
    width: auto !important;
    filter: blur(2px);
  }

  #platos::-webkit-scrollbar {
    height: 2px;
  }

  #platos::-webkit-scrollbar-thumb {
    background-color: #f47600ad;
    border-radius: 10px;
  }

  #order-info #total-price {
    font-size: 18px;
    font-weight: bold;
  }
</style>