<template>
    <div id="modal" v-if="visible">
      <div @click="cancelDelete" class="h-100 w-100 position-fixed z-1">
      </div>
      <div id="modal-content" class="rounded-4 p-3 z-2">
        <h6 class="fw-bold mb-3">¿Deseas eliminar este producto del carrito?</h6>
        <img :src="plate.photo ? plate.photo : noPhoto" class="card-img-top rounded-4 mb-2" style="height: 200px;object-fit: cover;" alt="...">
        <h6 class="text-center mb-3 px-1 py-2">{{ plate.name }}</h6>
        <button @click="confirmDelete" class="btn rounded-5 text-white mb-2 py-1" style="background-color: #F42C00;">Eliminar</button>
        <button @click="cancelDelete" class="btn rounded-5 text-white py-1" style="background-color: #F47700;">Cancelar</button>
      </div>
    </div>
  </template>
  
  <script>
import noPhoto from './../assets/no_plate.png';

  export default {
    name: 'DeleteProductConfirm',
    data() {
    return {
      noPhoto
    };
  },
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
      plate: {
        type: Object,
        default: null,
      },
    },
    methods: {
      confirmDelete() {
        this.$emit('confirm');
      },
      cancelDelete() {
        this.$emit('cancel');
      }
    }
  };
  </script>
  
  <style scoped>
  #modal {
    z-index: 100 !important;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(139, 139, 139, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  #modal-content {
    width: 80%;
    background: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }
  
  button {
    width:70%;
    cursor: pointer;
  }
  
  button:hover {
    background: #e65c00;
  }
  </style>
  